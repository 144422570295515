/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-empty */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider } from 'semantic-ui-react';
import {
  CardElement, useElements,
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';

import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie-player';

import lottieJson from '../animations/lottie.json';
import getBasePath from '../helpers/getBasePath';
import formatCurrency from '../helpers/formatCurrency';
import formatPeriod from '../helpers/formatPeriod';

import { processPayment } from '../stripe';

import Store from '../store';

import CheckoutPromoCode from './CheckoutPromoCode';

const CheckoutForm = ({ giftCode }) => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const elements = useElements();
  const store = Store.useContainer();
  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState();
  const [cardRequest, setCardRequest] = useState();
  const [loading, setLoading] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const handleStripeError = useCallback((err) => {
    let error;

    if ([
      'setup_intent_authentication_failure',
      'card_declined',
      'insufficient_funds',
      'expired_card',
      'incorrect_cvc',
      'incorrect_number',
    ].includes(err)) {
      error = t(`payment.error.${err}`);
    } else {
      error = t('payment.error.failed');
    }

    notify.show(
      error,
      'error',
    );
  }, [t]);

  const handlePaymentMethod = useCallback(
    async (evt) => {
      setPaymentLoading(true);

      const params = {
        pid: store.context.pid,
        tid: store.context.tid,
        slug: store.context.slug,
        period: store.period,
        amount: store.amount,
        currency: store.context.currency,
        optin: store.form.optIn,
        promoCode: store.promoCode?.id,
        gift: store.form.gift,
        giftPeriod: store.form.giftPeriod || null,
        giftAmount: store.giftAmount || null,
      };

      const d = await processPayment(
        stripe,
        {
          ...evt,
          payerFirstname: store.form.firstname,
          payerLastname: store.form.lastname,
          payerEmail: store.form.email,
          payerCountry: store.form.country,
          payerName: `${store.form.lastname} ${store.form.firstname}`,
          ...(evt?.paymentMethod?.id ? { payment_method: evt.paymentMethod.id } : {}),
        },
        params,
      );

      if (d && d.status === 'ERROR') {
        let error = t('payment.error.unknown', { email: store.context.metadata.supportEmail });
        switch (d.error) {
          case 'ALREADY_SUBSCRIBED':
            error = t('payment.error.already_exists');
            break;
          case 'PAYMENT_FAILED':
            error = t('payment.error.failed');
            break;
          case 'CVC_ERROR':
            error = t('payment.error.cvc');
            break;
          case 'STRIPE_ERROR':
            error = null;
            break;
          case 'INVALID_CODE':
            error = giftCode ? t('payment.error.invalid_gift_code') : t('payment.error.invalid_code');
            break;
          default:
            if (d.err) {
              error = d.err;
            }
            break;
        }

        if (error) {
          notify.show(
            error,
            'error',
          );
        }

        if (d.error === 'STRIPE_ERROR') {
          handleStripeError(d.details);
        }

        setTimeout(() => {
          setPaymentLoading(false);
        }, 2000);
        return;
      }

      if (d && d.status === 'SUCCESS') {
        history.push(`${getBasePath(location)}/verify`);
      }
    },
    [
      store.context, store.period, store.amount, store.form.optIn, store.form.gift,
      store.form.giftPeriod, store.form.firstname, store.form.lastname, store.form.email,
      store.form.country, store.promoCode.id, store.giftAmount,
      stripe, t, giftCode, history, location, handleStripeError,
    ],
  );

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.off('paymentmethod');
      paymentRequest.on('paymentmethod', handlePaymentMethod);
    }
  }, [paymentRequest, handlePaymentMethod]);

  const round = (value, precision) => {
    const multiplier = 10 ** precision || 0;
    return Math.round(value * multiplier) / multiplier;
  };

  useEffect(() => {
    const price = parseInt(Math.round(round(store.amount, 2) * 10 ** 2), 10);
    const total = {
      label: t('payment.label', {
        name: store.context.metadata.name,
        amount: formatCurrency(
          store.amount,
          store.context.currency,
        ),
        period: formatPeriod(store.period),
      }),
      amount: price,
    };

    if (stripe) {
      if (paymentRequest) {
        const a = async () => {
          try {
            paymentRequest.update({
              total,
            });
          } catch { }
        };
        a();
      } else {
        const pr = stripe.paymentRequest({
          country: 'FR',
          currency: store.context.currency,
          total,
          requestPayerName: false,
          requestPayerEmail: false,
        });

        pr.on('paymentmethod', handlePaymentMethod);

        // Check the availability of the Payment Request API first.
        pr.canMakePayment().then((result) => {
          if (result) {
            setPaymentRequest(pr);
            setCardRequest(pr);
          } else {
            setPaymentRequest(null);
            setCardRequest(pr);
          }
          setLoading(false);
        });
      }
    }
  }, [
    handlePaymentMethod,
    paymentRequest,
    store.amount,
    store.context.currency,
    store.context.metadata.name,
    store.period,
    stripe,
    t,
  ]);

  const handleCBPayment = async ({ card }) => {
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name: `${store.form.lastname} ${store.form.firstname}`,
        email: store.form.email,
      },
    });

    if (result.error?.type === 'validation_error' && result.error?.code) {
      handleStripeError(result.code);
      setPaymentLoading(false);
      return;
    }

    if (typeof result.error !== 'undefined') {
      throw new Error(result.error);
    }

    await handlePaymentMethod({
      card,
      payment_method: result.paymentMethod.id,
    });
  };

  const handleCodePayment = async () => {
    await handlePaymentMethod({
      code: giftCode,
    });
  };

  return (
    <>
      { paymentLoading && (
        <div style={{
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          backgroundColor: 'whitesmoke',
          zIndex: '999999',
        }}
        >
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ width: 250, height: 250 }}
          />
          <div className="payment-loading">{t(`payment.check_${giftCode ? 'gift' : 'bank'}`)}</div>
        </div>
      )}
      <Divider />
      {!giftCode && (
        <>
          { (typeof store.context.allowPromoCode === 'undefined' || store.context.allowPromoCode) && (
            <CheckoutPromoCode />
          )}

          {paymentRequest && (
            <div
              style={{
                position: 'relative',
              }}
            >
              {(!store.cgu
                || (store.form.country || '').trim() === ''
                || (store.form.firstname || '').trim() === ''
                || (store.form.lastname || '').trim() === ''
                || (store.form.email || '').trim() === '')
              && (
                <div
                  className="payment-no-cgu"
                  onClick={() => {
                    store.setShake(true);
                    setTimeout(() => {
                      store.setShake(false);
                    }, 1000);
                  }}
                />
              )}
              <PaymentRequestButtonElement
                options={{
                  paymentRequest,
                  style: {
                    paymentRequestButton: {
                      type: 'default',
                      theme: 'light',
                      height: '32px',
                    },
                  },
                }}
              />
              <div style={{
                fontSize: '.8em', width: '100%', textAlign: 'center', padding: '1.4em',
              }}
              >
                Ou
              </div>
            </div>
          )}

          <CardElement
            className="StripeElementCard"
            options={{
              paymentRequest: cardRequest,
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
        </>
      )}

      <div
        className="btn-form"
        style={{
          marginTop: '1em',
          position: 'relative',
        }}
      >
        {(!store.cgu
          || (store.form.country || '').trim() === ''
          || (store.form.firstname || '').trim() === ''
          || (store.form.lastname || '').trim() === ''
          || (store.form.email || '').trim() === '')
        && (
          <div
            className="payment-no-cgu"
            onClick={() => {
              store.setShake(true);
              setTimeout(() => {
                store.setShake(false);
              }, 1000);
            }}
          />
        )}
        <Button
          primary
          fluid
          loading={loading}
          disabled={loading}
          style={{
            marginRight: 0,
          }}
          onClick={async () => {
            setPaymentLoading(true);
            store.trackEventPremium('add_to_cart');

            try {
              if (giftCode) {
                await handleCodePayment();
              } else {
                await handleCBPayment({
                  card: elements.getElement(CardElement),
                });
              }
            } catch (e) {
              setLoading(false);
            }
          }}
        >
          {giftCode ? t('payment.by_gift') : t('payment.by_card')}
        </Button>
      </div>
    </>
  );
};

CheckoutForm.propTypes = {
  giftCode: PropTypes.string,
};

CheckoutForm.defaultProps = {
  giftCode: null,
};

export default CheckoutForm;
