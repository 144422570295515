const formatPeriod = (p) => {
  switch (p) {
    case 'monthly':
      return ' / mois';

    case 'yearly':
      return ' / an';

    default:
      return '';
  }
};

export default formatPeriod;
