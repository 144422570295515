import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Store from '../store';

const FormDropdown = ({
  placeholder,
  fieldName,
  options,
  defaultValue = 'fr',
  validator = () => true,
  transformer = (a) => a,
}) => {
  const store = Store.useContainer();

  const [field, setField] = useState(defaultValue);
  const [error, setError] = useState(false);
  const debouncedChangeHandler = useCallback(
    debounce((value) => {
      store.setForm((prev) => ({
        ...prev,
        [fieldName]: transformer(value),
      }));
    }, 300), [],
  );

  const [fieldShake, setFieldShake] = useState(false);
  useEffect(() => {
    if (!fieldShake && store.shake && (store.form[fieldName] || '').trim() === '') {
      setFieldShake(true);
    } else if (fieldShake && !(store.shake && (store.form[fieldName] || '').trim() === '')) {
      setFieldShake(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.shake, fieldShake, store.form[fieldName]]);

  return (
    <Dropdown
      search
      selection
      options={options}
      style={{ marginTop: '10px', marginBottom: '5px' }}
      placeholder={placeholder}
      fluid
      error={error}
      value={field}
      onChange={(evt, { value }) => {
        setField(transformer(value));
        setError(!validator(value) || value.trim() === '');
        debouncedChangeHandler(value);
      }}
    />
  );
};

FormDropdown.propTypes = {
  placeholder: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  transformer: PropTypes.func,
  validator: PropTypes.func,
  defaultValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    flag: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
};

FormDropdown.defaultProps = {
  validator: () => true,
  transformer: (a) => a,
  defaultValue: 'fr',
};

export default FormDropdown;
