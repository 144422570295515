import React from 'react';

import PropTypes from 'prop-types';
import {
  Dropdown, Item,
} from 'semantic-ui-react';

import Store from '../store';
import { API } from '../constants';

const PremiumDropdown = ({ loadOffer }) => {
  const store = Store.useContainer();

  return (
    <>
      { store.context.bundle?.pid
        && !store.userPremium?.user?.tid
        && store.context.premiumOffers.filter((offer) => offer.sellable).length > 0 && (
        <div className="bundle-container">
          <Dropdown
            fluid
            className="bundle-dropdown"
            selection
            trigger={(
              <Item className="selected-header-selected-item">
                <Item.Content>
                  <Item.Header as="a">{store.context.metadata.name}</Item.Header>
                  <Item.Meta>
                    {store.context.slug === store.context.bundle?.slug ? `Offre bundle - ${store.context.premiumOffers.length} podcasts` : 'Podcast unique'}
                  </Item.Meta>
                </Item.Content>
              </Item>
            )}
          >
            <Dropdown.Menu>
              <Dropdown.Item
                disabled={store.context.bundle.slug === store.context.slug}
                key={store.context.bundle.slug}
                onClick={() => {
                  loadOffer(`${API}/s/${store.context.bundle.slug}`);
                }}
              >
                <Item className="selected-header-item">
                  <Item.Image size="mini" src={store.context.bundle.imageUrl} />
                  <Item.Content>
                    <Item.Header as="a">{store.context.bundle.name}</Item.Header>
                    <Item.Meta>
                      {store.context.bundle.slug === store.context.bundle?.slug ? `Offre bundle - ${store.context.premiumOffers.length} podcasts` : 'Podcast unique'}
                    </Item.Meta>
                  </Item.Content>
                </Item>
              </Dropdown.Item>
              <Dropdown.Divider />
              {store.context.premiumOffers.filter((offer) => offer.sellable).map((offer) => (
                <Dropdown.Item
                  disabled={offer.slug === store.context.slug}
                  key={offer.slug}
                  onClick={() => {
                    loadOffer(`${API}/s/${offer.slug}`);
                  }}
                >
                  <Item className="selected-header-item">
                    <Item.Image size="mini" src={offer.imageUrl} />
                    <Item.Content>
                      <Item.Header as="a">{offer.name}</Item.Header>
                      <Item.Meta>
                        {offer.slug === store.context.bundle?.slug ? `Offre bundle - ${store.context.premiumOffers.length} podcasts` : 'Podcast unique'}
                      </Item.Meta>
                    </Item.Content>
                  </Item>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </>
  );
};

PremiumDropdown.propTypes = {
  loadOffer: PropTypes.func.isRequired,
};

export default PremiumDropdown;
