/* eslint-disable no-useless-escape */
import React, { useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import {
  Checkbox, Divider, Form, Icon, Loader,
} from 'semantic-ui-react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import CheckoutForm from './CheckoutForm';

import Store from '../store';
import getBasePath from '../helpers/getBasePath';
import { getUserInfos } from '../stripe';
import CheckBox from './CheckBox';
import FormField from './FormField';
import FormDropdown from './FormDropdown';
import formatCurrency from '../helpers/formatCurrency';
import EmailField from './EmailField';

const PaymentComponent = ({ giftCode }) => {
  const [t] = useTranslation();
  const countryOptions = [
    {
      flag: 'ad',
      key: 'ad',
      value: 'ad',
      text: t('countries.AD'),
    },
    {
      flag: 'ae',
      key: 'ae',
      value: 'ae',
      text: t('countries.AE'),
    },
    {
      flag: 'af',
      key: 'af',
      value: 'af',
      text: t('countries.AF'),
    },
    {
      flag: 'ag',
      key: 'ag',
      value: 'ag',
      text: t('countries.AG'),
    },
    {
      flag: 'ai',
      key: 'ai',
      value: 'ai',
      text: t('countries.AI'),
    },
    {
      flag: 'al',
      key: 'al',
      value: 'al',
      text: t('countries.AL'),
    },
    {
      flag: 'am',
      key: 'am',
      value: 'am',
      text: t('countries.AM'),
    },
    {
      flag: 'ao',
      key: 'ao',
      value: 'ao',
      text: t('countries.AO'),
    },
    {
      flag: 'ap',
      key: 'ap',
      value: 'ap',
      text: t('countries.AP'),
    },
    {
      flag: 'aq',
      key: 'aq',
      value: 'aq',
      text: t('countries.AQ'),
    },
    {
      flag: 'ar',
      key: 'ar',
      value: 'ar',
      text: t('countries.AR'),
    },
    {
      flag: 'as',
      key: 'as',
      value: 'as',
      text: t('countries.AS'),
    },
    {
      flag: 'at',
      key: 'at',
      value: 'at',
      text: t('countries.AT'),
    },
    {
      flag: 'au',
      key: 'au',
      value: 'au',
      text: t('countries.AU'),
    },
    {
      flag: 'aw',
      key: 'aw',
      value: 'aw',
      text: t('countries.AW'),
    },
    {
      flag: 'ax',
      key: 'ax',
      value: 'ax',
      text: t('countries.AX'),
    },
    {
      flag: 'az',
      key: 'az',
      value: 'az',
      text: t('countries.AZ'),
    },
    {
      flag: 'ba',
      key: 'ba',
      value: 'ba',
      text: t('countries.BA'),
    },
    {
      flag: 'bb',
      key: 'bb',
      value: 'bb',
      text: t('countries.BB'),
    },
    {
      flag: 'bd',
      key: 'bd',
      value: 'bd',
      text: t('countries.BD'),
    },
    {
      flag: 'be',
      key: 'be',
      value: 'be',
      text: t('countries.BE'),
    },
    {
      flag: 'bf',
      key: 'bf',
      value: 'bf',
      text: t('countries.BF'),
    },
    {
      flag: 'bg',
      key: 'bg',
      value: 'bg',
      text: t('countries.BG'),
    },
    {
      flag: 'bh',
      key: 'bh',
      value: 'bh',
      text: t('countries.BH'),
    },
    {
      flag: 'bi',
      key: 'bi',
      value: 'bi',
      text: t('countries.BI'),
    },
    {
      flag: 'bj',
      key: 'bj',
      value: 'bj',
      text: t('countries.BJ'),
    },
    {
      flag: 'bl',
      key: 'bl',
      value: 'bl',
      text: t('countries.BL'),
    },
    {
      flag: 'bm',
      key: 'bm',
      value: 'bm',
      text: t('countries.BM'),
    },
    {
      flag: 'bn',
      key: 'bn',
      value: 'bn',
      text: t('countries.BN'),
    },
    {
      flag: 'bo',
      key: 'bo',
      value: 'bo',
      text: t('countries.BO'),
    },
    {
      flag: 'bq',
      key: 'bq',
      value: 'bq',
      text: t('countries.BQ'),
    },
    {
      flag: 'br',
      key: 'br',
      value: 'br',
      text: t('countries.BR'),
    },
    {
      flag: 'bs',
      key: 'bs',
      value: 'bs',
      text: t('countries.BS'),
    },
    {
      flag: 'bt',
      key: 'bt',
      value: 'bt',
      text: t('countries.BT'),
    },
    {
      flag: 'bv',
      key: 'bv',
      value: 'bv',
      text: t('countries.BV'),
    },
    {
      flag: 'bw',
      key: 'bw',
      value: 'bw',
      text: t('countries.BW'),
    },
    {
      flag: 'by',
      key: 'by',
      value: 'by',
      text: t('countries.BY'),
    },
    {
      flag: 'bz',
      key: 'bz',
      value: 'bz',
      text: t('countries.BZ'),
    },
    {
      flag: 'ca',
      key: 'ca',
      value: 'ca',
      text: t('countries.CA'),
    },
    {
      flag: 'cc',
      key: 'cc',
      value: 'cc',
      text: t('countries.CC'),
    },
    {
      flag: 'cd',
      key: 'cd',
      value: 'cd',
      text: t('countries.CD'),
    },
    {
      flag: 'cf',
      key: 'cf',
      value: 'cf',
      text: t('countries.CF'),
    },
    {
      flag: 'cg',
      key: 'cg',
      value: 'cg',
      text: t('countries.CG'),
    },
    {
      flag: 'ch',
      key: 'ch',
      value: 'ch',
      text: t('countries.CH'),
    },
    {
      flag: 'ci',
      key: 'ci',
      value: 'ci',
      text: t('countries.CI'),
    },
    {
      flag: 'ck',
      key: 'ck',
      value: 'ck',
      text: t('countries.CK'),
    },
    {
      flag: 'cl',
      key: 'cl',
      value: 'cl',
      text: t('countries.CL'),
    },
    {
      flag: 'cm',
      key: 'cm',
      value: 'cm',
      text: t('countries.CM'),
    },
    {
      flag: 'cn',
      key: 'cn',
      value: 'cn',
      text: t('countries.CN'),
    },
    {
      flag: 'co',
      key: 'co',
      value: 'co',
      text: t('countries.CO'),
    },
    {
      flag: 'cr',
      key: 'cr',
      value: 'cr',
      text: t('countries.CR'),
    },
    {
      flag: 'cu',
      key: 'cu',
      value: 'cu',
      text: t('countries.CU'),
    },
    {
      flag: 'cv',
      key: 'cv',
      value: 'cv',
      text: t('countries.CV'),
    },
    {
      flag: 'cw',
      key: 'cw',
      value: 'cw',
      text: t('countries.CW'),
    },
    {
      flag: 'cx',
      key: 'cx',
      value: 'cx',
      text: t('countries.CX'),
    },
    {
      flag: 'cy',
      key: 'cy',
      value: 'cy',
      text: t('countries.CY'),
    },
    {
      flag: 'cz',
      key: 'cz',
      value: 'cz',
      text: t('countries.CZ'),
    },
    {
      flag: 'de',
      key: 'de',
      value: 'de',
      text: t('countries.DE'),
    },
    {
      flag: 'dj',
      key: 'dj',
      value: 'dj',
      text: t('countries.DJ'),
    },
    {
      flag: 'dk',
      key: 'dk',
      value: 'dk',
      text: t('countries.DK'),
    },
    {
      flag: 'dm',
      key: 'dm',
      value: 'dm',
      text: t('countries.DM'),
    },
    {
      flag: 'do',
      key: 'do',
      value: 'do',
      text: t('countries.DO'),
    },
    {
      flag: 'dz',
      key: 'dz',
      value: 'dz',
      text: t('countries.DZ'),
    },
    {
      flag: 'ec',
      key: 'ec',
      value: 'ec',
      text: t('countries.EC'),
    },
    {
      flag: 'ee',
      key: 'ee',
      value: 'ee',
      text: t('countries.EE'),
    },
    {
      flag: 'eg',
      key: 'eg',
      value: 'eg',
      text: t('countries.EG'),
    },
    {
      flag: 'eh',
      key: 'eh',
      value: 'eh',
      text: t('countries.EH'),
    },
    {
      flag: 'er',
      key: 'er',
      value: 'er',
      text: t('countries.ER'),
    },
    {
      flag: 'es',
      key: 'es',
      value: 'es',
      text: t('countries.ES'),
    },
    {
      flag: 'et',
      key: 'et',
      value: 'et',
      text: t('countries.ET'),
    },
    {
      flag: 'eu',
      key: 'eu',
      value: 'eu',
      text: t('countries.EU'),
    },
    {
      flag: 'fi',
      key: 'fi',
      value: 'fi',
      text: t('countries.FI'),
    },
    {
      flag: 'fj',
      key: 'fj',
      value: 'fj',
      text: t('countries.FJ'),
    },
    {
      flag: 'fk',
      key: 'fk',
      value: 'fk',
      text: t('countries.FK'),
    },
    {
      flag: 'fm',
      key: 'fm',
      value: 'fm',
      text: t('countries.FM'),
    },
    {
      flag: 'fo',
      key: 'fo',
      value: 'fo',
      text: t('countries.FO'),
    },
    {
      flag: 'fr',
      key: 'fr',
      value: 'fr',
      text: t('countries.FR'),
    },
    {
      flag: 'ga',
      key: 'ga',
      value: 'ga',
      text: t('countries.GA'),
    },
    {
      flag: 'gb',
      key: 'gb',
      value: 'gb',
      text: t('countries.GB'),
    },
    {
      flag: 'gd',
      key: 'gd',
      value: 'gd',
      text: t('countries.GD'),
    },
    {
      flag: 'ge',
      key: 'ge',
      value: 'ge',
      text: t('countries.GE'),
    },
    {
      flag: 'gf',
      key: 'gf',
      value: 'gf',
      text: t('countries.GF'),
    },
    {
      flag: 'gg',
      key: 'gg',
      value: 'gg',
      text: t('countries.GG'),
    },
    {
      flag: 'gh',
      key: 'gh',
      value: 'gh',
      text: t('countries.GH'),
    },
    {
      flag: 'gi',
      key: 'gi',
      value: 'gi',
      text: t('countries.GI'),
    },
    {
      flag: 'gl',
      key: 'gl',
      value: 'gl',
      text: t('countries.GL'),
    },
    {
      flag: 'gm',
      key: 'gm',
      value: 'gm',
      text: t('countries.GM'),
    },
    {
      flag: 'gn',
      key: 'gn',
      value: 'gn',
      text: t('countries.GN'),
    },
    {
      flag: 'gp',
      key: 'gp',
      value: 'gp',
      text: t('countries.GP'),
    },
    {
      flag: 'gq',
      key: 'gq',
      value: 'gq',
      text: t('countries.GQ'),
    },
    {
      flag: 'gr',
      key: 'gr',
      value: 'gr',
      text: t('countries.GR'),
    },
    {
      flag: 'gs',
      key: 'gs',
      value: 'gs',
      text: t('countries.GS'),
    },
    {
      flag: 'gt',
      key: 'gt',
      value: 'gt',
      text: t('countries.GT'),
    },
    {
      flag: 'gu',
      key: 'gu',
      value: 'gu',
      text: t('countries.GU'),
    },
    {
      flag: 'gw',
      key: 'gw',
      value: 'gw',
      text: t('countries.GW'),
    },
    {
      flag: 'gy',
      key: 'gy',
      value: 'gy',
      text: t('countries.GY'),
    },
    {
      flag: 'hk',
      key: 'hk',
      value: 'hk',
      text: t('countries.HK'),
    },
    {
      flag: 'hm',
      key: 'hm',
      value: 'hm',
      text: t('countries.HM'),
    },
    {
      flag: 'hn',
      key: 'hn',
      value: 'hn',
      text: t('countries.HN'),
    },
    {
      flag: 'hr',
      key: 'hr',
      value: 'hr',
      text: t('countries.HR'),
    },
    {
      flag: 'ht',
      key: 'ht',
      value: 'ht',
      text: t('countries.HT'),
    },
    {
      flag: 'hu',
      key: 'hu',
      value: 'hu',
      text: t('countries.HU'),
    },
    {
      flag: 'id',
      key: 'id',
      value: 'id',
      text: t('countries.ID'),
    },
    {
      flag: 'ie',
      key: 'ie',
      value: 'ie',
      text: t('countries.IE'),
    },
    {
      flag: 'il',
      key: 'il',
      value: 'il',
      text: t('countries.IL'),
    },
    {
      flag: 'im',
      key: 'im',
      value: 'im',
      text: t('countries.IM'),
    },
    {
      flag: 'in',
      key: 'in',
      value: 'in',
      text: t('countries.IN'),
    },
    {
      flag: 'io',
      key: 'io',
      value: 'io',
      text: t('countries.IO'),
    },
    {
      flag: 'iq',
      key: 'iq',
      value: 'iq',
      text: t('countries.IQ'),
    },
    {
      flag: 'ir',
      key: 'ir',
      value: 'ir',
      text: t('countries.IR'),
    },
    {
      flag: 'is',
      key: 'is',
      value: 'is',
      text: t('countries.IS'),
    },
    {
      flag: 'it',
      key: 'it',
      value: 'it',
      text: t('countries.IT'),
    },
    {
      flag: 'je',
      key: 'je',
      value: 'je',
      text: t('countries.JE'),
    },
    {
      flag: 'jm',
      key: 'jm',
      value: 'jm',
      text: t('countries.JM'),
    },
    {
      flag: 'jo',
      key: 'jo',
      value: 'jo',
      text: t('countries.JO'),
    },
    {
      flag: 'jp',
      key: 'jp',
      value: 'jp',
      text: t('countries.JP'),
    },
    {
      flag: 'ke',
      key: 'ke',
      value: 'ke',
      text: t('countries.KE'),
    },
    {
      flag: 'kg',
      key: 'kg',
      value: 'kg',
      text: t('countries.KG'),
    },
    {
      flag: 'kh',
      key: 'kh',
      value: 'kh',
      text: t('countries.KH'),
    },
    {
      flag: 'ki',
      key: 'ki',
      value: 'ki',
      text: t('countries.KI'),
    },
    {
      flag: 'km',
      key: 'km',
      value: 'km',
      text: t('countries.KM'),
    },
    {
      flag: 'kn',
      key: 'kn',
      value: 'kn',
      text: t('countries.KN'),
    },
    {
      flag: 'kp',
      key: 'kp',
      value: 'kp',
      text: t('countries.KP'),
    },
    {
      flag: 'kr',
      key: 'kr',
      value: 'kr',
      text: t('countries.KR'),
    },
    {
      flag: 'kw',
      key: 'kw',
      value: 'kw',
      text: t('countries.KW'),
    },
    {
      flag: 'ky',
      key: 'ky',
      value: 'ky',
      text: t('countries.KY'),
    },
    {
      flag: 'kz',
      key: 'kz',
      value: 'kz',
      text: t('countries.KZ'),
    },
    {
      flag: 'la',
      key: 'la',
      value: 'la',
      text: t('countries.LA'),
    },
    {
      flag: 'lb',
      key: 'lb',
      value: 'lb',
      text: t('countries.LB'),
    },
    {
      flag: 'lc',
      key: 'lc',
      value: 'lc',
      text: t('countries.LC'),
    },
    {
      flag: 'li',
      key: 'li',
      value: 'li',
      text: t('countries.LI'),
    },
    {
      flag: 'lk',
      key: 'lk',
      value: 'lk',
      text: t('countries.LK'),
    },
    {
      flag: 'lr',
      key: 'lr',
      value: 'lr',
      text: t('countries.LR'),
    },
    {
      flag: 'ls',
      key: 'ls',
      value: 'ls',
      text: t('countries.LS'),
    },
    {
      flag: 'lt',
      key: 'lt',
      value: 'lt',
      text: t('countries.LT'),
    },
    {
      flag: 'lu',
      key: 'lu',
      value: 'lu',
      text: t('countries.LU'),
    },
    {
      flag: 'lv',
      key: 'lv',
      value: 'lv',
      text: t('countries.LV'),
    },
    {
      flag: 'ly',
      key: 'ly',
      value: 'ly',
      text: t('countries.LY'),
    },
    {
      flag: 'ma',
      key: 'ma',
      value: 'ma',
      text: t('countries.MA'),
    },
    {
      flag: 'mc',
      key: 'mc',
      value: 'mc',
      text: t('countries.MC'),
    },
    {
      flag: 'md',
      key: 'md',
      value: 'md',
      text: t('countries.MD'),
    },
    {
      flag: 'me',
      key: 'me',
      value: 'me',
      text: t('countries.ME'),
    },
    {
      flag: 'mf',
      key: 'mf',
      value: 'mf',
      text: t('countries.MF'),
    },
    {
      flag: 'mg',
      key: 'mg',
      value: 'mg',
      text: t('countries.MG'),
    },
    {
      flag: 'mh',
      key: 'mh',
      value: 'mh',
      text: t('countries.MH'),
    },
    {
      flag: 'mk',
      key: 'mk',
      value: 'mk',
      text: t('countries.MK'),
    },
    {
      flag: 'ml',
      key: 'ml',
      value: 'ml',
      text: t('countries.ML'),
    },
    {
      flag: 'mm',
      key: 'mm',
      value: 'mm',
      text: t('countries.MM'),
    },
    {
      flag: 'mn',
      key: 'mn',
      value: 'mn',
      text: t('countries.MN'),
    },
    {
      flag: 'mo',
      key: 'mo',
      value: 'mo',
      text: t('countries.MO'),
    },
    {
      flag: 'mp',
      key: 'mp',
      value: 'mp',
      text: t('countries.MP'),
    },
    {
      flag: 'mq',
      key: 'mq',
      value: 'mq',
      text: t('countries.MQ'),
    },
    {
      flag: 'mr',
      key: 'mr',
      value: 'mr',
      text: t('countries.MR'),
    },
    {
      flag: 'ms',
      key: 'ms',
      value: 'ms',
      text: t('countries.MS'),
    },
    {
      flag: 'mt',
      key: 'mt',
      value: 'mt',
      text: t('countries.MT'),
    },
    {
      flag: 'mu',
      key: 'mu',
      value: 'mu',
      text: t('countries.MU'),
    },
    {
      flag: 'mv',
      key: 'mv',
      value: 'mv',
      text: t('countries.MV'),
    },
    {
      flag: 'mw',
      key: 'mw',
      value: 'mw',
      text: t('countries.MW'),
    },
    {
      flag: 'mx',
      key: 'mx',
      value: 'mx',
      text: t('countries.MX'),
    },
    {
      flag: 'my',
      key: 'my',
      value: 'my',
      text: t('countries.MY'),
    },
    {
      flag: 'mz',
      key: 'mz',
      value: 'mz',
      text: t('countries.MZ'),
    },
    {
      flag: 'na',
      key: 'na',
      value: 'na',
      text: t('countries.NA'),
    },
    {
      flag: 'nc',
      key: 'nc',
      value: 'nc',
      text: t('countries.NC'),
    },
    {
      flag: 'ne',
      key: 'ne',
      value: 'ne',
      text: t('countries.NE'),
    },
    {
      flag: 'nf',
      key: 'nf',
      value: 'nf',
      text: t('countries.NF'),
    },
    {
      flag: 'ng',
      key: 'ng',
      value: 'ng',
      text: t('countries.NG'),
    },
    {
      flag: 'ni',
      key: 'ni',
      value: 'ni',
      text: t('countries.NI'),
    },
    {
      flag: 'nl',
      key: 'nl',
      value: 'nl',
      text: t('countries.NL'),
    },
    {
      flag: 'no',
      key: 'no',
      value: 'no',
      text: t('countries.NO'),
    },
    {
      flag: 'np',
      key: 'np',
      value: 'np',
      text: t('countries.NP'),
    },
    {
      flag: 'nr',
      key: 'nr',
      value: 'nr',
      text: t('countries.NR'),
    },
    {
      flag: 'nu',
      key: 'nu',
      value: 'nu',
      text: t('countries.NU'),
    },
    {
      flag: 'nz',
      key: 'nz',
      value: 'nz',
      text: t('countries.NZ'),
    },
    {
      flag: 'om',
      key: 'om',
      value: 'om',
      text: t('countries.OM'),
    },
    {
      flag: 'pa',
      key: 'pa',
      value: 'pa',
      text: t('countries.PA'),
    },
    {
      flag: 'pe',
      key: 'pe',
      value: 'pe',
      text: t('countries.PE'),
    },
    {
      flag: 'pf',
      key: 'pf',
      value: 'pf',
      text: t('countries.PF'),
    },
    {
      flag: 'pg',
      key: 'pg',
      value: 'pg',
      text: t('countries.PG'),
    },
    {
      flag: 'ph',
      key: 'ph',
      value: 'ph',
      text: t('countries.PH'),
    },
    {
      flag: 'pk',
      key: 'pk',
      value: 'pk',
      text: t('countries.PK'),
    },
    {
      flag: 'pl',
      key: 'pl',
      value: 'pl',
      text: t('countries.PL'),
    },
    {
      flag: 'pm',
      key: 'pm',
      value: 'pm',
      text: t('countries.PM'),
    },
    {
      flag: 'pn',
      key: 'pn',
      value: 'pn',
      text: t('countries.PN'),
    },
    {
      flag: 'pr',
      key: 'pr',
      value: 'pr',
      text: t('countries.PR'),
    },
    {
      flag: 'ps',
      key: 'ps',
      value: 'ps',
      text: t('countries.PS'),
    },
    {
      flag: 'pt',
      key: 'pt',
      value: 'pt',
      text: t('countries.PT'),
    },
    {
      flag: 'pw',
      key: 'pw',
      value: 'pw',
      text: t('countries.PW'),
    },
    {
      flag: 'py',
      key: 'py',
      value: 'py',
      text: t('countries.PY'),
    },
    {
      flag: 'qa',
      key: 'qa',
      value: 'qa',
      text: t('countries.QA'),
    },
    {
      flag: 're',
      key: 're',
      value: 're',
      text: t('countries.RE'),
    },
    {
      flag: 'ro',
      key: 'ro',
      value: 'ro',
      text: t('countries.RO'),
    },
    {
      flag: 'rs',
      key: 'rs',
      value: 'rs',
      text: t('countries.RS'),
    },
    {
      flag: 'ru',
      key: 'ru',
      value: 'ru',
      text: t('countries.RU'),
    },
    {
      flag: 'rw',
      key: 'rw',
      value: 'rw',
      text: t('countries.RW'),
    },
    {
      flag: 'sa',
      key: 'sa',
      value: 'sa',
      text: t('countries.SA'),
    },
    {
      flag: 'sb',
      key: 'sb',
      value: 'sb',
      text: t('countries.SB'),
    },
    {
      flag: 'sc',
      key: 'sc',
      value: 'sc',
      text: t('countries.SC'),
    },
    {
      flag: 'sd',
      key: 'sd',
      value: 'sd',
      text: t('countries.SD'),
    },
    {
      flag: 'se',
      key: 'se',
      value: 'se',
      text: t('countries.SE'),
    },
    {
      flag: 'sg',
      key: 'sg',
      value: 'sg',
      text: t('countries.SG'),
    },
    {
      flag: 'sh',
      key: 'sh',
      value: 'sh',
      text: t('countries.SH'),
    },
    {
      flag: 'si',
      key: 'si',
      value: 'si',
      text: t('countries.SI'),
    },
    {
      flag: 'sj',
      key: 'sj',
      value: 'sj',
      text: t('countries.SJ'),
    },
    {
      flag: 'sk',
      key: 'sk',
      value: 'sk',
      text: t('countries.SK'),
    },
    {
      flag: 'sl',
      key: 'sl',
      value: 'sl',
      text: t('countries.SL'),
    },
    {
      flag: 'sm',
      key: 'sm',
      value: 'sm',
      text: t('countries.SM'),
    },
    {
      flag: 'sn',
      key: 'sn',
      value: 'sn',
      text: t('countries.SN'),
    },
    {
      flag: 'so',
      key: 'so',
      value: 'so',
      text: t('countries.SO'),
    },
    {
      flag: 'sr',
      key: 'sr',
      value: 'sr',
      text: t('countries.SR'),
    },
    {
      flag: 'ss',
      key: 'ss',
      value: 'ss',
      text: t('countries.SS'),
    },
    {
      flag: 'st',
      key: 'st',
      value: 'st',
      text: t('countries.ST'),
    },
    {
      flag: 'sv',
      key: 'sv',
      value: 'sv',
      text: t('countries.SV'),
    },
    {
      flag: 'sx',
      key: 'sx',
      value: 'sx',
      text: t('countries.SX'),
    },
    {
      flag: 'sy',
      key: 'sy',
      value: 'sy',
      text: t('countries.SY'),
    },
    {
      flag: 'sz',
      key: 'sz',
      value: 'sz',
      text: t('countries.SZ'),
    },
    {
      flag: 'tc',
      key: 'tc',
      value: 'tc',
      text: t('countries.TC'),
    },
    {
      flag: 'td',
      key: 'td',
      value: 'td',
      text: t('countries.TD'),
    },
    {
      flag: 'tf',
      key: 'tf',
      value: 'tf',
      text: t('countries.TF'),
    },
    {
      flag: 'tg',
      key: 'tg',
      value: 'tg',
      text: t('countries.TG'),
    },
    {
      flag: 'th',
      key: 'th',
      value: 'th',
      text: t('countries.TH'),
    },
    {
      flag: 'tj',
      key: 'tj',
      value: 'tj',
      text: t('countries.TJ'),
    },
    {
      flag: 'tk',
      key: 'tk',
      value: 'tk',
      text: t('countries.TK'),
    },
    {
      flag: 'tl',
      key: 'tl',
      value: 'tl',
      text: t('countries.TL'),
    },
    {
      flag: 'tm',
      key: 'tm',
      value: 'tm',
      text: t('countries.TM'),
    },
    {
      flag: 'tn',
      key: 'tn',
      value: 'tn',
      text: t('countries.TN'),
    },
    {
      flag: 'to',
      key: 'to',
      value: 'to',
      text: t('countries.TO'),
    },
    {
      flag: 'tr',
      key: 'tr',
      value: 'tr',
      text: t('countries.TR'),
    },
    {
      flag: 'tt',
      key: 'tt',
      value: 'tt',
      text: t('countries.TT'),
    },
    {
      flag: 'tv',
      key: 'tv',
      value: 'tv',
      text: t('countries.TV'),
    },
    {
      flag: 'tw',
      key: 'tw',
      value: 'tw',
      text: t('countries.TW'),
    },
    {
      flag: 'tz',
      key: 'tz',
      value: 'tz',
      text: t('countries.TZ'),
    },
    {
      flag: 'ua',
      key: 'ua',
      value: 'ua',
      text: t('countries.UA'),
    },
    {
      flag: 'ug',
      key: 'ug',
      value: 'ug',
      text: t('countries.UG'),
    },
    {
      flag: 'um',
      key: 'um',
      value: 'um',
      text: t('countries.UM'),
    },
    {
      flag: 'us',
      key: 'us',
      value: 'us',
      text: t('countries.US'),
    },
    {
      flag: 'uy',
      key: 'uy',
      value: 'uy',
      text: t('countries.UY'),
    },
    {
      flag: 'uz',
      key: 'uz',
      value: 'uz',
      text: t('countries.UZ'),
    },
    {
      flag: 'va',
      key: 'va',
      value: 'va',
      text: t('countries.VA'),
    },
    {
      flag: 'vc',
      key: 'vc',
      value: 'vc',
      text: t('countries.VC'),
    },
    {
      flag: 've',
      key: 've',
      value: 've',
      text: t('countries.VE'),
    },
    {
      flag: 'vg',
      key: 'vg',
      value: 'vg',
      text: t('countries.VG'),
    },
    {
      flag: 'vi',
      key: 'vi',
      value: 'vi',
      text: t('countries.VI'),
    },
    {
      flag: 'vn',
      key: 'vn',
      value: 'vn',
      text: t('countries.VN'),
    },
    {
      flag: 'vu',
      key: 'vu',
      value: 'vu',
      text: t('countries.VU'),
    },
    {
      flag: 'wf',
      key: 'wf',
      value: 'wf',
      text: t('countries.WF'),
    },
    {
      flag: 'ws',
      key: 'ws',
      value: 'ws',
      text: t('countries.WS'),
    },
    {
      flag: 'ye',
      key: 'ye',
      value: 'ye',
      text: t('countries.YE'),
    },
    {
      flag: 'yt',
      key: 'yt',
      value: 'yt',
      text: t('countries.YT'),
    },
    {
      flag: 'za',
      key: 'za',
      value: 'za',
      text: t('countries.ZA'),
    },
    {
      flag: 'zm',
      key: 'zm',
      value: 'zm',
      text: t('countries.ZM'),
    },
    {
      flag: 'zw',
      key: 'zw',
      value: 'zw',
      text: t('countries.ZW'),
    },
  ];

  const location = useLocation();
  const history = useHistory();

  const store = Store.useContainer();

  const params = (new URLSearchParams(location.search));

  const [loading, setLoading] = useState(false);

  const handleRadioChange = (e) => {
    const { value } = e.currentTarget;

    let amount;
    switch (value) {
      case '6months':
        amount = 6 * parseFloat(store.context.config.prices.monthly[0]);
        break;

      case '12months':
        amount = store.context.config.prices.yearly?.[0]
          ? parseFloat(store.context.config.prices.yearly?.[0])
          : 12 * parseFloat(store.context.config.prices.monthly[0]);
        break;

      case '24months':
        amount = store.context.config.prices.yearly?.[0]
          ? 2 * parseFloat(store.context.config.prices.yearly?.[0])
          : 24 * parseFloat(store.context.config.prices.monthly[0]);
        break;

      default:
        break;
    }
    store.setGiftAmount(amount);

    store.setForm((prev) => ({
      ...prev,
      giftPeriod: value,
    }));
  };

  return loading ? (
    <Loader active />
  ) : (
    <div className="payment">
      <div style={{ width: '100%', textAlign: 'left' }}>
        <Link
          to={`${getBasePath(location)}/`}
        >
          <Icon name="left arrow" />
          {t('login.back')}
        </Link>
      </div>

      <FormField
        fieldName="firstname"
        placeholder={t('form.firstname')}
        icon="user"
      />
      <FormField
        fieldName="lastname"
        placeholder={t('form.lastname')}
        icon="user"
      />
      <EmailField
        fieldName="email"
        placeholder={t('form.email')}
        icon="at"
      />

      <FormDropdown
        fieldName="country"
        placeholder={t('form.country')}
        defaultValue={store.context.metadata?.language === 'en' ? 'gb' : (store.context.metadata?.language || 'fr')}
        options={countryOptions.sort((c1, c2) => c1.text.localeCompare(c2.text))}
      />

      {!giftCode && (typeof store.context.allowGift === 'undefined' || store.context.allowGift) && (
        <>
          <Form.Field style={{
            marginTop: '10px',
            marginBottom: '5px',
          }}
          >
            <Checkbox
              toggle
              size="mini"
              label={t('form.gift')}
              checked={store.form.gift}
              onChange={(evt, { checked }) => {
                if (checked && store.context.slug[0] === 'S') {
                  handleRadioChange({
                    currentTarget: {
                      value: '12months',
                    },
                  });
                }

                store.setForm((prev) => ({
                  ...prev,
                  gift: checked,
                }));
              }}
            />
          </Form.Field>

          {store.form.gift && store.context.slug[0] === 'S' && (
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <div className="switch-toggle switch-3 switch-candy">
                <input
                  id="months6"
                  value="6months"
                  name="state-d"
                  type="radio"
                  checked={store.form.giftPeriod === '6months'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="months6" className="label-payment">
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    {t('period.gift.6months')}
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {t('period.gift.price', {
                      amount: formatCurrency(
                        6 * parseFloat(store.context.config.prices.monthly[0]),
                        store.context.currency,
                      ),
                    })}
                  </div>
                </label>

                <input
                  id="months12"
                  value="12months"
                  name="state-d"
                  type="radio"
                  checked={store.form.giftPeriod === '12months'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="months12" className="label-payment">
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    {t('period.gift.12months')}
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {t('period.gift.price', {
                      amount: formatCurrency(
                        store.context.config.prices.yearly?.[0]
                          ? parseFloat(store.context.config.prices.yearly?.[0])
                          : 12 * parseFloat(store.context.config.prices.monthly[0]),
                        store.context.currency,
                      ),
                    })}
                  </div>
                </label>

                <input
                  id="months24"
                  value="24months"
                  name="state-d"
                  type="radio"
                  checked={store.form.giftPeriod === '24months'}
                  onChange={handleRadioChange}
                />
                <label htmlFor="months24" className="label-payment">
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '12px',
                    }}
                  >
                    {t('period.gift.24months')}
                  </div>
                  <div
                    style={{
                      fontSize: '12px',
                    }}
                  >
                    {t('period.gift.price', {
                      amount: formatCurrency(
                        store.context.config.prices.yearly?.[0]
                          ? 2 * parseFloat(store.context.config.prices.yearly?.[0])
                          : 24 * parseFloat(store.context.config.prices.monthly[0]),
                        store.context.currency,
                      ),
                    })}
                  </div>
                </label>
              </div>
            </div>
          )}
        </>
      )}

      <Divider />

      <div className="payment-button">
        <CheckBox />

        <Checkbox
          label={(
            <label>
              {store.context.metadata.optinText
                || t('form.optin')}
            </label>
          )}
          checked={store.form.optIn}
          onChange={(evt, { checked }) => {
            store.setForm((prev) => ({
              ...prev,
              optIn: checked,
            }));
          }}
        />
      </div>

      {store.stripe && (
        <Elements stripe={store.stripe}>
          <CheckoutForm
            giftCode={params.get('k')}
            onPaymentSucceeded={async ({ feed, token }, isGift) => {
              store.resetForm();

              store.trackEventPremium('purchase');
              if (isGift) {
                history.push(`${getBasePath(location)}/thankyou`);
              } else {
                setLoading(true);
                store.setContext((ctx) => ({
                  ...ctx,
                  feed,
                  feedToken: token,
                }));
                localStorage.setItem(`token_${store.context.pid}`, token);
                store.setUserPremium(await getUserInfos(store.context.slug, token));
                history.push(`${getBasePath(location)}/account`);
                setLoading(false);
              }
            }}
          />
        </Elements>
      )}

      <div className="privacy">
        <Trans i18nKey="privacy">
          Vos données personnelles font l&apos;objet d&apos;un traitement
          informatique par Audiomeans en tant que sous-traitant agissant
          pour le compte des éditeurs de podcasts. Vos données personnelles
          sont utilisées pour la création et la gestion de votre compte,
          l&apos;accès aux podcasts et l&apos;envoi par les éditeurs de
          podcasts de communications relatives à leurs contenus.
          Pour en savoir plus et pour exercer vos droits, consultez la
          {' '}
          <a href="https://audiomeans.fr/politique-de-confidentialite" rel="noopener noreferrer" target="_blank">politique de données personnelles</a>
.
        </Trans>
      </div>
    </div>
  );
};

PaymentComponent.propTypes = {
  giftCode: PropTypes.string,
};

PaymentComponent.defaultProps = {
  giftCode: null,
};

export default PaymentComponent;
