import i18n from 'i18next';

import en from './en.json';
import es from './es.json';
import fr from './fr.json';

i18n
  .init({
    resources: {
      fr: { translations: fr },
      en: { translations: en },
      es: { translations: es },
    },
    fallbackLng: 'fr',
    debug: false,

    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },

    react: {
      wait: true,
    },

    detection: {
      order: ['localStorage', 'navigator'],

      // keys or params to lookup language from
      lookupLocalStorage: 'audmns-lng',

      // cache user language on
      caches: ['localStorage'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

      // only detect languages that are in the whitelist
      checkWhitelist: true,
      checkForSimilarInWhitelist: false,
    },
  });

export default i18n;
