import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Shake } from 'reshake';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Store from '../store';

const FormField = ({
  placeholder,
  icon,
  fieldName,
  validator = () => true,
  transformer = (a) => a,
}) => {
  const store = Store.useContainer();

  const [field, setField] = useState('');
  const [error, setError] = useState(false);
  const debouncedChangeHandler = useCallback(
    debounce((value) => {
      store.setForm((prev) => ({
        ...prev,
        [fieldName]: transformer(value),
      }));
    }, 300), [],
  );

  const [fieldShake, setFieldShake] = useState(false);
  useEffect(() => {
    if (!fieldShake && store.shake && (store.form[fieldName] || '').trim() === '') {
      setFieldShake(true);
    } else if (fieldShake && !(store.shake && (store.form[fieldName] || '').trim() === '')) {
      setFieldShake(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.shake, fieldShake, store.form[fieldName]]);

  return (
    <Shake h={10} v={0} r={0} q={4} active={fieldShake} fixed>
      <Input
        style={{ marginTop: '10px', marginBottom: '5px' }}
        placeholder={placeholder}
        fluid
        icon={icon}
        iconPosition="left"
        error={error}
        value={field}
        onChange={(evt, { value }) => {
          setField(transformer(value));
          setError(!validator(value) || value.trim() === '');
          debouncedChangeHandler(value);
        }}
      />
    </Shake>
  );
};

FormField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  transformer: PropTypes.func,
  validator: PropTypes.func,
};

FormField.defaultProps = {
  validator: () => true,
  transformer: (a) => a,
};

export default FormField;
