import React from 'react';
import {
  Link, Route, Switch, useLocation,
} from 'react-router-dom';

import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import { useTranslation } from 'react-i18next';

import ModalPreview from '../ModalPreview';

import Store from '../../store';
import EmailWallInput from './EmailWallInput';
import getBasePath from '../../helpers/getBasePath';

import '../../App.scss';
import AlreadyPaid from '../AlreadyPaid';
import PremiumUserAccount from '../userPremium/PremiumUserAccount';
import PremiumDropdown from '../PremiumDropdown';
import PaymentComponent from '../PaymentComponent';
import PaymentChecker from '../PaymentChecker';

const EmailWallComponent = ({ loadOffer }) => {
  const [t] = useTranslation();
  const store = Store.useContainer();
  const location = useLocation();

  return (
    <>
      { (location?.search || '').indexOf('src=sl') > -1 && (
        <Link
          to={store.context.metadata.slugAccount}
          target="_blank"
          style={{
            textDecoration: 'underline',
            display: 'flex',
            justifyContent: 'center',
            color: '#1a78c2 !important',
          }}
        >
          {t('panorama.other_header')}
        </Link>
      )}
      <MetaTags>
        <title>{store.context.metadata.name}</title>
        <meta name="description" content={store.context.metadata.name} />
      </MetaTags>

      <PremiumDropdown loadOffer={loadOffer} />

      <div className="content">
        <div className="main">
          <div className="header">
            <div className="img-podcast">
              <img
                src={store.context.metadata.imageUrl}
                alt={store.context.metadata.name}
              />
            </div>
            <div className="infos-podcast" style={{ paddingLeft: '1.3em' }}>
              <h2>{store.context.metadata.name}</h2>
              <h3>{store.context.podcast.authorName}</h3>
              {location.pathname.indexOf('/login') === -1 && location.pathname.indexOf('/gift') === -1 && (
                <>
                  <p style={{ fontSize: '1rem' }}>{t('login.private_access')}</p>
                  <ModalPreview />
                </>
              )}
            </div>
          </div>

          <div className="main-right">
            <Switch>
              <Route path={`${getBasePath(location)}/login`}>
                <AlreadyPaid />
              </Route>

              <Route path={`${getBasePath(location)}/account`}>
                <PremiumUserAccount />
              </Route>

              <Route path={`${getBasePath(location)}/verify`}>
                <PaymentChecker />
              </Route>

              <Route path={`${getBasePath(location)}/gift`}>
                <PaymentComponent giftCode />
              </Route>

              <Route path="/">
                <div className="description-container">
                  <div className="h1-header">
                    {ReactHtmlParser(store.context.metadata.description)}
                  </div>
                </div>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bottom: '1em',
                    flexDirection: 'column',
                  }}
                >
                  <EmailWallInput />
                </div>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

EmailWallComponent.propTypes = {
  loadOffer: PropTypes.func.isRequired,
};

export default EmailWallComponent;
