/* eslint-disable no-empty */
import React, { useState } from 'react';

import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Button } from 'semantic-ui-react';
import { notify } from 'react-notify-toast';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { setupIntent, updatePayment } from '../../stripe/index';
import Store from '../../store';

const PremiumCBUpdate = ({ toggle }) => {
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);

  const elements = useElements();
  const stripe = useStripe();
  const store = Store.useContainer();

  // const offer = store.context;
  const token = localStorage.getItem(`token_${store.context.pid}`);

  const handleSubmit = async () => {
    setLoading(true);

    const dataSetup = await setupIntent(
      store.context.pid,
      store.context.slug,
      token,
    );

    if (dataSetup && dataSetup.status === 'ERROR') {
      setLoading(false);
      notify.show(
        t('user.update.error'),
        'error',
      );
      return;
    }

    let result;
    try {
      result = await stripe.confirmCardSetup(dataSetup.token, {
        payment_method: {
          type: 'card',
          card: elements.getElement(CardElement),
        },
      });
    } catch {}

    if (typeof result.error !== 'undefined') {
      let err = t('user.update.cb.error');
      if (result.error.code === 'setup_intent_authentication_failure') {
        err = t('user.update.cb.verification');
      } else if (result.error.code === 'card_declined') {
        err = t('user.update.cb.declined');
      } else if (result.error.code === 'expired_card') {
        err = t('user.update.cb.expired');
      } else if (result.error.code === 'incorrect_cvc') {
        err = t('user.update.cb.cvc');
      } else if (result.error.code === 'incorrect_number') {
        err = t('user.update.cb.code');
      }

      notify.show(err, 'error');
      setLoading(false);
      return;
    }

    try {
      const res = await updatePayment(
        store.context.pid,
        result.setupIntent.payment_method,
        store.context.slug,
        token,
      );

      if (res.status === 'ERROR') {
        notify.show(
          t('user.update.error'),
          'error',
        );
      } else {
        notify.show(t('user.update.success'), 'success');
        toggle();
      }
    } catch (e) {
      notify.show(
        t('user.update.error'),
        'error',
      );
    }

    setLoading(false);
  };

  return (
    <>
      <CardElement
        className="StripeElementCard"
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}
      />

      <Button
        basic
        color="grey"
        disabled={loading}
        loading={loading}
        style={{ width: '130px', border: '1px solid #d6d6d6' }}
        onClick={toggle}
      >
        {t('cancel')}
      </Button>

      <Button
        disabled={loading}
        loading={loading}
        primary
        style={{ width: '130px', margin: '1.25em' }}
        onClick={handleSubmit}
      >
        {t('validate')}
      </Button>
    </>
  );
};

PremiumCBUpdate.propTypes = {
  toggle: PropTypes.func.isRequired,
};

export default PremiumCBUpdate;
