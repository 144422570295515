/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import { Button, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import { processEmailFile } from '../stripe';

import Store from '../store';

const FileComponent = () => {
  const [t] = useTranslation();
  const { slug, id } = useParams();
  const store = Store.useContainer();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [hasSentEmail, setHasSentEmail] = useState(false);

  const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

  const validateEmail = (e, { value }) => emailRegex.test(value);

  const handleChange = (evt, dt) => {
    setEmail(dt.value.trim().replace(/(\r\n|\n|\r)/gm, ''));
    setEmailError(!validateEmail(evt, dt) || dt.value === '');
  };

  const handleSubmit = async () => {
    setLoading(true);

    await processEmailFile(
      {
        email,
        eid: id,
      },
      slug,
    );

    setHasSentEmail(true);
    setLoading(false);
  };

  const handleKeyPressEmail = (e) => {
    if (e.charCode === 32 || e.charCode === 13) {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <MetaTags>
        <title>{store.context.metadata.name}</title>
        <meta name="description" content={store.context.metadata.name} />
      </MetaTags>
      <div className="content">
        <div
          className="main"
        >
          <div className="header">
            <div className="img-podcast">
              <img
                src={store.context.metadata.imageUrl}
                alt={store.context.metadata.name}
              />
            </div>
            <div className="infos-podcast" style={{ paddingLeft: '1.3em' }}>
              <h2>{store.context.metadata.name}</h2>
              <h3>{store.context.podcast?.authorName || ''}</h3>
            </div>
          </div>

          <div className="main-right">
            <div className="description-container">
              <div className="h1-header">
                {t('premium_file.access', { name: store.context.metadata.name })}
              </div>
            </div>

            <div
              className="payment"
              style={{
                height: 'auto',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Input
                fluid
                size="large"
                key="email"
                icon="mail"
                iconPosition="left"
                name={t('premium_file.email')}
                placeholder="Email"
                value={email}
                onChange={handleChange}
                error={emailError}
                disabled={hasSentEmail}
                onKeyPress={handleKeyPressEmail}
                style={{ marginBottom: '5px', width: '100%', height: '38px' }}
              />

              <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                <Button
                  color="blue"
                  size="large"
                  loading={loading}
                  disabled={
                    loading
                    || hasSentEmail
                    || (!!(emailError !== false || email === ''))
                  }
                  onClick={handleSubmit}
                >
                  Recevoir le contenu
                </Button>
              </div>

              <div style={{
                paddingTop: '20px',
                textAlign: 'center',
              }}
              >
                <a href={`/s/${slug}`}>{t('premium_file.not_yet_subscriber')}</a>
              </div>

              {hasSentEmail && (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: 1,
                }}
                >
                  <div style={{ textAlign: 'center', paddingTop: '10px' }}>
                    {t('premium_file.message')}
                  </div>
                  <div style={{ flex: 1 }} />
                  <div
                    className="container-footer"
                  >
                    <div className="footer-member-area" style={{ padding: 0 }}>
                      <a
                        href={`mailto:${store.context.metadata.supportEmail}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          fontSize: '14px',
                        }}
                      >
                        {t('premium_file.contact')}
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default FileComponent;
