import { useState } from 'react';
import { createContainer } from 'unstated-next';

const Store = () => {
  const [context, setContext] = useState({});
  const [stripe, setStripe] = useState();

  const [amount, setAmount] = useState(5);
  const [promoCode, setPromoCode] = useState({});
  const [giftAmount, setGiftAmount] = useState(0);
  const [period, setPeriod] = useState();
  const [userPremium, setUserPremium] = useState({});
  const [cgu, setCgu] = useState(false);
  const [shake, setShake] = useState(false);
  const [spotifyUri, setSpotifyUri] = useState(null);

  const [session, setSession] = useState(new Date().getTime());

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenPreview, setModalOpenPreview] = useState(false);

  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    country: 'fr',
    gift: false,
    giftPeriod: '12months',
  });

  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setForm({
      firstname: '',
      lastname: '',
      email: '',
      country: 'fr',
      gift: false,
      giftPeriod: '12months',
    });
    setCgu(false);
    setPromoCode({});
  };

  const trackEventPremium = (event, slug) => {
    const offerToLoad = slug || context.slug;
    if (window.gtag) {
      try {
        window.gtag('event', event, {
          offer: offerToLoad.replace(/^[PS]-/g, ''),
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  return {
    context,
    setContext,

    stripe,
    setStripe,

    amount,
    setAmount,

    period,
    setPeriod,

    modalOpen,
    setModalOpen,

    modalOpenPreview,
    setModalOpenPreview,

    resetForm,

    userPremium,
    setUserPremium,

    session,
    setSession,

    giftAmount,
    setGiftAmount,

    cgu,
    setCgu,
    shake,
    setShake,

    promoCode,
    setPromoCode,

    form,
    setForm,
    errors,
    setErrors,

    spotifyUri,
    setSpotifyUri,

    trackEventPremium,
  };
};

export default createContainer(Store);
