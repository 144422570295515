import React from 'react';
import { Divider, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Store from '../store/index';

const PanoramaItem = ({ el }) => {
  const [t] = useTranslation();

  return (
    <Link to={`${el.offerType === 'BUNDLE' ? el.ukey.replace('P-', 'S-') : el.ukey}?src=sl`} target="_blank" key={el.ukey}>
      <div className="panorama-card-container">
        <Image
          src={el.img}
          style={{ height: '110px', borderRadius: '4px' }}
        />

        <div className="panorama-card-content">
          <p
            style={{
              fontSize: '1.2rem',
              color: 'black',
              fontWeight: '800',
            }}
          >
            {el.title}
          </p>

          <div
            style={{
              fontSize: '1.1rem',
              color: '#333',
              whiteSpace: 'nowrap',
              textAlign: 'right',
              width: '100%',
            }}
          >
            {el.offerType === 'EMAIL_WALL'
              ? t('panorama.private')
              : `${el.price} €`}
            {['STREAM', 'BUNDLE'].includes(el.offerType) && t('panorama.per_month')}
          </div>
        </div>
      </div>
    </Link>
  );
};

PanoramaItem.propTypes = {
  el: PropTypes.shape({
    ukey: PropTypes.string.isRequired,
    img: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    offerType: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
  }).isRequired,
};

const Panorama = () => {
  const [t] = useTranslation();

  const store = Store.useContainer();
  const arrData = store.context.metadata;

  return (
    <>
      <h2
        style={{
          padding: '1.5em 0',
          textAlign: 'center',
          fontSize: '1.75rem',
        }}
      >
        {t('panorama.header')}
      </h2>

      {arrData.filter((a) => a.offerType === 'BUNDLE').length > 0 && (
        <>
          {arrData.filter((a) => a.offerType === 'BUNDLE').map((el) => (
            <PanoramaItem el={el} />
          ))}
          <Divider />
        </>
      )}

      {arrData.filter((a) => a.offerType !== 'BUNDLE').map((el) => (
        <PanoramaItem el={el} />
      ))}
    </>
  );
};

export default Panorama;
