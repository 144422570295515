import React from 'react';
import {
  Link, Route, Switch, useLocation,
} from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import MetaTags from 'react-meta-tags';
import PropTypes from 'prop-types';
import PaymentComponent from './PaymentComponent';
import ModalPreview from './ModalPreview';
import Store from '../store';
import PremiumDropdown from './PremiumDropdown';
import AlreadyPaid from './AlreadyPaid';
import getBasePath from '../helpers/getBasePath';
import OfferSelectionComponent from './OfferSelectionComponent';
import PremiumUserAccount from './userPremium/PremiumUserAccount';
import formatCurrency from '../helpers/formatCurrency';
import PaymentChecker from './PaymentChecker';

const PodcastComponent = ({ loadOffer }) => {
  const [t] = useTranslation();

  const store = Store.useContainer();
  const location = useLocation();

  const getPriceTextFromPeriod = () => {
    if (!store.context.sellable || !store.context.payable) {
      return <></>;
    }

    const isStream = store.context.slug[0] === 'S';
    if (store.form.gift && isStream) {
      return (
        <>
          {
        formatCurrency(
          store.giftAmount,
          store.context.currency,
        )
        }
        </>
      );
    }

    switch (store.period) {
      case 'monthly':
        return <>{t('prices.month', { amount: store.amount })}</>;

      case 'onetime':
        return <>{t('prices.onetime', { amount: store.amount })}</>;

      case 'yearly':
        return <>{t('prices.year', { amount: store.amount })}</>;

      case 'code':
        return (
          <></>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      { (location?.search || '').indexOf('src=sl') > -1 && (
        <Link
          to={store.context.metadata.slugAccount}
          target="_blank"
          style={{
            textDecoration: 'underline',
            display: 'flex',
            justifyContent: 'center',
            color: '#1a78c2 !important',
          }}
        >
          {t('panorama.other_header')}
        </Link>
      )}

      <PremiumDropdown loadOffer={loadOffer} />

      <MetaTags>
        <title>{store.context.metadata.name}</title>
        <meta name="description" content={store.context.metadata.name} />
      </MetaTags>
      <div className="content">
        <div className="main">
          <div className="header">
            <div className="img-podcast">
              <img
                src={store.context.metadata.imageUrl}
                alt={store.context.metadata.name}
              />
            </div>
            <div className="infos-podcast" style={{ paddingLeft: '1.3em' }}>
              <h2>{store.context.metadata.name}</h2>
              <h3>{store.context.podcast?.authorName || ''}</h3>
              {
                location.pathname.indexOf('/login') === -1
                && location.pathname.indexOf('/account') === -1
                && location.pathname.indexOf('/thankyou') === -1
                && location.pathname.indexOf('/gift') === -1
                && location.pathname.indexOf('/verify') === -1
                && (
                  <>
                    <p>{getPriceTextFromPeriod()}</p>
                    <ModalPreview />
                  </>
                )
              }
            </div>
          </div>
          <div className="main-right">
            <Switch>
              <Route path={`${getBasePath(location)}/login`}>
                <AlreadyPaid />
              </Route>

              <Route path={`${getBasePath(location)}/verify`}>
                <PaymentChecker />
              </Route>

              { store.context.payable && (
                <Route path={`${getBasePath(location)}/checkout`}>
                  <PaymentComponent />
                </Route>
              )}

              <Route path={`${getBasePath(location)}/gift`}>
                <PaymentComponent giftCode />
              </Route>

              <Route path={`${getBasePath(location)}/account`}>
                <PremiumUserAccount />
              </Route>

              <Route path={`${getBasePath(location)}/thankyou`}>
                <div className="description-container">
                  <div className="h1-header" style={{ textAlign: 'center' }}>
                    <h3>{t('payment.gift_thank_you_header')}</h3>
                    <p>{t('payment.gift_thank_you_text')}</p>
                  </div>
                </div>
              </Route>

              <Route path="/">
                <div className="description-container">
                  <div className="h1-header">
                    {ReactHtmlParser(store.context.metadata.description)}
                  </div>
                </div>

                <div className="container">
                  <OfferSelectionComponent />
                </div>
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

PodcastComponent.propTypes = {
  loadOffer: PropTypes.func.isRequired,
};

export default PodcastComponent;
