/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';

import { processPromoCode } from '../stripe';
import Store from '../store';
import formatCurrency from '../helpers/formatCurrency';

const CheckoutPromoCode = () => {
  const [t] = useTranslation();

  const store = Store.useContainer();
  const [step, setStep] = useState('INIT');

  const [loading, setLoading] = useState(false);
  const [promoCode, setPromoCode] = useState('');

  const checkPromoCode = async () => {
    if ((promoCode || '').trim() === '') {
      return;
    }
    setLoading(true);

    const result = await processPromoCode({
      promoCode: promoCode.trim(),
      tid: store.context.tid,
    }, store.context.slug);

    if (result.status === 'ERROR') {
      notify.show(
        t('promo_code.invalid'),
        'error',
      );
    } else {
      const v = store.context.config.prices[store.period === 'onetime' ? store.period : 'monthly'];
      store.setAmount(parseFloat(v[0]));
      store.setPeriod((prev) => (prev === 'onetime' ? prev : 'monthly'));

      store.setPromoCode(result.coupon);
    }

    setLoading(false);
  };

  const getDeducedAmount = (code) => {
    if (code.amountOff) {
      return Math.max(0.0, store.amount - code.amountOff / 100.0);
    }
    return store.amount * (1 - code.percentOff / 100.0);
  };

  const formatPromoCode = (code) => {
    let str = '';
    if (code.amountOff) {
      str += t('promo_code.amount_off', { amount: formatCurrency(code.amountOff / 100.0, 'eur') });
    } else {
      str += t('promo_code.percent_off', { amount: code.percentOff });
    }

    if (store.period !== 'onetime') {
      str += t('promo_code.duration_off', {
        cnt: code.months || '1',
        off: formatCurrency(getDeducedAmount(code)),
        off_months: code.months || '1',
        normal_amount: formatCurrency(store.amount),
      });
    } else {
      str += t('promo_code.onetime');
    }

    return str;
  };

  return (
    <div
      className="premium-promo-code"
      style={{
        width: '100%',
        marginTop: '1em',
        marginBottom: '1em',
      }}
    >
      {store.promoCode?.id ? (
        <Message color="green" size="mini">
          {formatPromoCode(store.promoCode)}
        </Message>
      ) : (
        <>
          {step === 'INIT' && (
            <span
              role="button"
              tabIndex={0}
              style={{
                textDecoration: 'underline',
                color: '#1a78c2',
              }}
              onClick={() => setStep('FORM')}
              className="premium-already-member"
            >
              {t('promo_code.use_promo_code')}
            </span>
          )}

          {step === 'FORM' && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
              className="promo-form-container"
            >
              <Form.Input
                fluid
                placeholder={t('promo_code.placeholder')}
                value={promoCode}
                onChange={(evt, { value }) => setPromoCode(value)}
                style={{
                  flex: 1,
                }}
              />
              <Button icon="times" circular loading={loading} disabled={loading} onClick={() => { setPromoCode(''); setStep('INIT'); }} />
              <Button icon="check" circular loading={loading} disabled={loading} onClick={() => checkPromoCode()} />
            </div>
          )}

        </>
      )}
    </div>
  );
};

export default CheckoutPromoCode;
