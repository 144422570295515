/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { notify } from 'react-notify-toast';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Form, Checkbox, Button, Input,
} from 'semantic-ui-react';
import {
  processEmail, processCode, alreadyCode, getUserInfos,
} from '../../stripe';
import getBasePath from '../../helpers/getBasePath';

import Store from '../../store';

const EmailWallInput = () => {
  const [t] = useTranslation();
  const store = Store.useContainer();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [hasSentEmail, setHasSentEmail] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState();
  const [isLogin, setIsLogin] = useState(false);

  const [optIn, setOptIn] = useState(false);

  const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;

  const validateEmail = (e, { value }) => emailRegex.test(value);

  const handleChange = (evt, dt) => {
    setEmail(dt.value.trim().replace(/(\r\n|\n|\r)/gm, ''));
    setEmailError(!validateEmail(evt, dt) || dt.value === '');
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!hasSentEmail) {
      const res = await processEmail(
        {
          pid: store.context.pid,
          tid: store.context.tid,
          email,
        },
        store.context.slug,
      );

      if (res.status === 'ERROR') {
        switch (res.error) {
          case 'FORBIDDEN_EMAIL':
            notify.show(
              t('login.error.forbidden'),
              'error',
            );
            break;

          case 'ALREADY_SUBSCRIBED':
            notify.show(
              t('payment.error.already_exists'),
              'error',
            );
            break;

          default:
            notify.show(
              t('login.error.unknown', { email: store.context.metadata.supportEmail }),
              'error',
            );
            break;
        }
      } else {
        if (res.data === 'LOGIN') {
          setIsLogin(true);
        }
        setHasSentEmail(true);
      }
    } else {
      const res = isLogin
        ? await alreadyCode(store.context.slug, {
          pid: store.context.pid,
          email,
          code: (code || '').trim(),
        })
        : await processCode({
          pid: store.context.pid,
          tid: store.context.tid,
          email,
          code: (code || '').trim(),
          optin: optIn,
        });

      if (res.status === 'ERROR') {
        switch (res.error) {
          case 'FORBIDDEN_EMAIL':
            notify.show(
              t('login.error.forbidden'),
              'error',
            );
            break;

          case 'ALREADY_SUBSCRIBED':
            notify.show(
              t('payment.error.already_exists'),
              'error',
            );
            break;

          case 'INVALID_EMAIL':
            notify.show(t('login.error.invalid_email'), 'error');
            break;

          case 'INVALID_CODE':
            notify.show(t('login.error.invalid_code'), 'error');
            break;

          default:
            notify.show(
              t('login.error.unknown', { email: store.context.metadata.supportEmail }),
              'error',
            );
            break;
        }
      } else {
        store.setContext((ctx) => ({
          ...ctx,
          feed: res.feed,
          feedToken: res.token,
        }));
        localStorage.setItem(`token_${store.context.pid}`, res.token);
        store.setUserPremium(await getUserInfos(store.context.slug, res.token));
        history.replace(`${getBasePath(location)}/account`);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="payment" style={{ height: 'auto', width: '100%' }}>
        <Input
          fluid
          size="large"
          key="email"
          icon="mail"
          iconPosition="left"
          name="email"
          placeholder={t('login.form_email')}
          value={email}
          onChange={handleChange}
          error={emailError}
          disabled={hasSentEmail}
          onKeyPress={(e) => {
            if (e.charCode === 32 || e.charCode === 13) {
              e.preventDefault();
              handleSubmit();
            }
          }}
          style={{ marginBottom: '5px', width: '100%', height: '38px' }}
        />

        <div style={{ textAlign: 'center', paddingTop: '10px' }}>
          <Button
            color="blue"
            size="large"
            loading={loading}
            disabled={
              loading
              || hasSentEmail
              || (!!(emailError !== false || email === ''))
            }
            onClick={handleSubmit}
          >
            {t('email.access_content')}
          </Button>
        </div>
        {hasSentEmail && (
          <>
            <p
              style={{
                fontSize: '14px',
                marginTop: '1.2em',
                marginBottom: '1.2em',
              }}
            >
              {t('email.explain_1')}
            </p>
            {!isLogin && (
              <div className="cgu">
                <Checkbox
                  label={(
                    <label>
                      {store.context?.metadata?.optinText
                        || t('form.optin')}
                    </label>
                  )}
                  checked={optIn}
                  onChange={(evt, { checked }) => {
                    setOptIn(checked);
                  }}
                />
              </div>
            )}
            <Form.Input
              placeholder={t('login.form_code')}
              fluid
              icon="lock"
              iconPosition="left"
              error={codeError}
              value={code}
              onKeyPress={(e) => {
                if (e.charCode === 32 || e.charCode === 13) {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
              style={{ marginBottom: '3em' }}
              onChange={(evt, dt) => {
                setCode(dt.value.trim().replace(/(\r\n|\n|\r)/gm, ''));
                setCodeError(dt.value === '');
              }}
            />
            <div className="connexion-btn">
              <Button
                primary
                fluid
                onClick={handleSubmit}
                disabled={loading}
                loading={loading}
              >
                {t('login.validate_code')}
              </Button>
              {hasSentEmail && (
                <p
                  style={{
                    color: 'black',
                    paddingTop: '15px',
                    textAlign: 'center',
                  }}
                  onClick={() => {
                    setIsLogin(false);
                    setHasSentEmail(false);
                  }}
                >
                  {t('login.retry_code')}
                </p>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

EmailWallInput.propTypes = {};

export default EmailWallInput;
