/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Store from '../store';

import '../App.scss';

function ModalPreview() {
  const [t] = useTranslation();
  const store = Store.useContainer();

  const handleOpen = (evt) => {
    evt.preventDefault();
    store.setModalOpenPreview(true);
  };

  return (
    <>
      {store?.context?.metadata?.extract && (
        <div className="modal-container">
          <Modal
            className="ui.dimmer ui.dimmer.modal"
            open={store.modalOpenPreview}
            closeOnDimmerClick={false}
            trigger={(
              <a href="#" style={{ color: 'black' }} onClick={handleOpen}>
                <Icon name="play circle" />
                {t('listen_extract')}
              </a>
            )}
            onOpen={handleOpen}
            basic
            closeIcon={(
              <Icon
                size="big"
                style={{
                  color: '#1a78c2',
                  float: 'right',
                  cursor: 'pointer',
                  position: 'fixed',
                  top: '2px',
                  right: '2px',
                }}
                onClick={() => store.setModalOpenPreview(false)}
                name="times"
              />
            )}
            style={{ padding: '30px 0', backgroundColor: 'none', overflow: 'hidden !important' }}
          >
            <Modal.Content>
              <div className="player-content" style={{ textAlign: 'center' }}>
                <iframe
                  title="player preview"
                  src={store.context.metadata.extract}
                  width="320px"
                  height="568px"
                  frameBorder="0"
                  allow="autoplay"
                />
              </div>
            </Modal.Content>
          </Modal>
        </div>
      )}
    </>
  );
}

export default ModalPreview;
