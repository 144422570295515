/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Modal, Icon, List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Store from '../../store';

import '../../App.scss';

function PremiumModalPodcast({ playerVar }) {
  const [t] = useTranslation();
  const store = Store.useContainer();

  if (!playerVar) {
    return (<></>);
  }

  const handleOpen = (evt) => {
    evt.preventDefault();
    store.setModalOpenPreview(true);
  };

  return (
    <List.Content>
      <Modal
        className="ui.dimmer ui.dimmer.modal"
        open={store.modalOpenPreview}
        closeOnDimmerClick={false}
        trigger={(
          <a
            style={{
              color: 'black',
              fontWeight: '700',
            }}
            role="link"
            onClick={handleOpen}
          >
            <List.Header style={{ paddingTop: '5px' }}>
              <Icon name="play circle" size="big" color="blue" />
              {t('user.listen_here')}
            </List.Header>
          </a>
        )}
        onOpen={handleOpen}
        basic
        closeIcon={(
          <Icon
            size="big"
            style={{
              color: '#1a78c2',
              float: 'right',
              cursor: 'pointer',
              position: 'fixed',
              top: '2px',
              right: '2px',
            }}
            onClick={() => store.setModalOpenPreview(false)}
            name="times"
          />
        )}
        style={{ padding: '30px 0', backgroundColor: 'none' }}
      >
        <Modal.Content>
          <div className="player-content" style={{ textAlign: 'center' }}>
            <iframe
              src={`${playerVar.url}&oid=${store.context.pid}&pfid=${store.userPremium.user.feedId}&tm=${playerVar.tm}&hm=${playerVar.signature}`}
              width="320px"
              height="568px"
              frameBorder="0"
              allow="autoplay"
              title="audmns-player"
            />
          </div>
        </Modal.Content>
      </Modal>
    </List.Content>
  );
}

PremiumModalPodcast.propTypes = {
  playerVar: PropTypes.shape({
    url: PropTypes.string.isRequired,
    tm: PropTypes.string.isRequired,
    signature: PropTypes.string.isRequired,
  }).isRequired,
};

export default PremiumModalPodcast;
