/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-empty */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import './App.scss';

import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import {
  Switch, Route, useParams,
  useHistory, useLocation,
} from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import Notifications from 'react-notify-toast';
import Helmet from 'react-helmet';
import { API } from './constants';
import Store from './store';

import PodcastComponent from './components/PodcastComponent';
import Panorama from './components/Panorama';

import EmailWallComponent from './components/EmailWall/EmailWallComponent';
import { getUserInfos, retrieveOffer } from './stripe';
import FileComponent from './components/FileComponent';
import getBasePath from './helpers/getBasePath';
import loadFb from './helpers/loadFb';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(STRIPE_KEY);

let stripePromise;
const getStripe = (stripeKey) => {
  if (!stripePromise) {
    stripePromise = loadStripe(stripeKey);
  }
  return stripePromise;
};

const App = () => {
  const store = Store.useContainer();
  // eslint-disable-next-line no-unused-vars
  const [_, i18n] = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_path: history.location.pathname + history.location.search + history.location.hash,
        });
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  const loadOffer = async (url) => {
    let urlToLoad = url;
    if (url.indexOf('/s/') > -1) {
      urlToLoad = `${urlToLoad.split('/s/')[0]}/s/${urlToLoad.split('/s/')[1].split('/')[0]}`;
    }

    setLoading(true);
    retrieveOffer(urlToLoad)
      .then(async (data) => {
        i18n.changeLanguage((data?.metadata?.language || 'fr').toLowerCase());

        if (['data-wall', 'email-wall'].includes(data.type)) {
          store.setContext(data);
        } else {
          store.setContext(data);

          if (data.config) {
            store.setAmount(data.config.prices[data.config.periods[0]][0]);
            store.setPeriod(data.config.periods[0]);
          }
        }

        store.setForm((prev) => ({
          ...prev,
          country: data.metadata?.language === 'en' ? 'gb' : (data.metadata?.language || 'fr'),
        }));

        // Set css class
        const x = document.createElement('STYLE');
        x.innerHTML = `
        :root {
           --main-color: ${data?.metadata?.colors?.main || '#ffffff'};
          --background-color: ${
            data?.metadata?.colors?.background || 'whitesmoke'
};
          --title-color: ${data?.metadata?.colors?.title || '#ffffff'};
          --selected-color: ${
            data?.metadata?.colors?.selected || '#000000'
};
        }
  
        body, #root, .ui.inverted.menu, .ui.fluid.container, .content .main > div, .switch-toggle { background-color: var(--background-color) !important; }
      `;
        document.head.appendChild(x);

        if (!window.location.pathname.match(/.*\/cgu.*/) && data.stripeKey) {
          store.setStripe(getStripe(data.stripeKey));
        }
        // 1 recuperer token
        const token = localStorage.getItem(`token_${data.pid}`);

        if (token) {
          try {
            const userInfo = await getUserInfos(data.slug, token);
            if (userInfo?.user?.tid) {
              store.setUserPremium(userInfo);
              store.setContext((ctx) => ({
                ...ctx,
                feed: userInfo.user.feed,
                feedToken: token,
              }));

              if (!window.location.href.match(/^.*\/file\/[a-zA-Z0-9-_]*/)) {
                history.replace(`${getBasePath(location)}/account`);
              }
            }
          } catch (e) {
            console.log(e);
          }
        }

        // Axceptio
        try {
          if (data.tracking?.axeptioId && !window._axcbInitialized) {
            window._axcbInitialized = true;
            window._axcb = [];
            window._axcb.push((axeptio) => {
              axeptio.on('cookies:complete', (choices) => {
                if (choices.google_analytics && data.tracking?.gaId) {
                  const googleTagManager = document.createElement('script');
                  googleTagManager.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-F7M0V3B0TH');
                  document.body.appendChild(googleTagManager);

                  window.dataLayer = window.dataLayer || [];
                  window.gtag = function () { window.dataLayer.push(arguments); };
                  window.gtag('js', new Date());
                  window.gtag('config', data.tracking?.gaId, {
                    anonymize_ip: false,
                    custom_map: {
                      dimension1: 'offer',
                    },
                  });

                  store.trackEventPremium('page_view', data.slug);
                }

                if (choices.facebook_pixel && data.tracking?.fbId) {
                  loadFb(data.tracking?.fbId);
                }
              });
            });

            const [clientId, cookiesVersion] = data.tracking?.axeptioId.split('/');
            window.axeptioSettings = {
              clientId,
              cookiesVersion,
            };

            const t = document.getElementsByTagName('script')[0];
            const e = document.createElement('script');
            e.async = true; e.src = '//static.axept.io/sdk.js';
            t.parentNode.insertBefore(e, t);
          }
        } catch (e) {
          console.log('Axeptio initialisation failed', e);
        }

        setLoading(false);
      });
  };

  useEffect(() => {
    if (window.location.search.indexOf('spOffer=spotify:show') > -1) {
      try {
        store.setSpotifyUri(window.location.search.split('spOffer=')[1].split('&')[0]);
      } catch (e) {}
    }

    if (
      !window.location.pathname.match(/\/cgu$/)
      && (
        window.location.pathname.match(/\/[a-zA-Z]\/[0-9a-zA-Z]*.*/)
        || window.location.pathname.match(/\/[0-9a-zA-Z]*[^/]*$/)
        || window.location.pathname === '/'
      )
    ) {
      let url = `${API}${window.location.pathname}`;

      if (window.location.pathname.match(/\/[a-zA-Z]\/[0-9a-zA-Z]*.*/)) {
        url = `${API}${window.location.pathname}`;
      } else if (window.location.pathname === '/') {
        url = `${API}/s/offer`;
      } else if (window.location.pathname.match(/\/[0-9a-zA-Z]*[^/]*$/)) {
        url = `${API}/s/offer${window.location.pathname}`;
      } else if (window.location.pathname === '/cgu') {
        url = `${API}/s/offer/cgu`;
      } else if (window.location.pathname === '/s/heGGZNLH') {
        url = `${API}/s/S-heGGZNLH`;
      }

      loadOffer(url);
    } else {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RedirectToCGU = () => {
    const { premiumid } = useParams();

    useEffect(() => {
      if (premiumid) {
        return;
      }

      fetch(`${API}/s/offer/cgu`, { credentials: 'include' })
        .then((data) => data.json())
        .then(async (data) => {
          window.location.replace(`https://audiomeanscgu.s3.amazonaws.com/2021-10-08/offers/${data.pid}.pdf`);
        });
    }, [premiumid]);

    if (premiumid) {
      window.location.replace(`${API}/s/${premiumid}/cgu-redir`);
      return (<></>);
    }

    return (<></>);
  };

  return (
    <>
      <Notifications />
      {loading && <Loader active />}

      <Helmet>
        <title>{store.context?.metadata?.name || 'Podcast Premium'}</title>
        <meta name="description" content={store.context?.podcast?.authorName || ''} />
      </Helmet>

      <Switch>
        <Route
          path="/:type/:premiumid/cgu"
          exact
          component={RedirectToCGU}
        />

        <Route
          path="/cgu"
          exact
          component={RedirectToCGU}
        />

        {!loading && (
          <Route path="/s/:slug/file/:id" exact>
            <FileComponent />
          </Route>
        )}

        {!loading && !store.context.type && (
          <Route path="/">
            <PodcastComponent loadOffer={loadOffer} />
          </Route>
        )}

        {!loading && store.context.type === 'email-wall' && (
          <Route path="/">
            <EmailWallComponent loadOffer={loadOffer} />
          </Route>
        )}

        {!loading && store.context.type === 'panorama' && (
          <Route path="/">
            <Panorama />
          </Route>
        )}
      </Switch>
    </>
  );
};

export default App;
