/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Button } from 'semantic-ui-react';
import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import Store from '../store';
import getBasePath from '../helpers/getBasePath';

const OfferSelectionComponent = () => {
  const [t] = useTranslation();

  const location = useLocation();
  const store = Store.useContainer();

  const handlePeriodChange = (e) => {
    store.setPromoCode({});

    const period = e.currentTarget.value;

    store.setPeriod(period);

    const v = store.context.config.prices[period];
    store.setAmount(parseFloat(v[0]));
  };

  return (
    <div className="payment">
      <div className="tip-txt" />

      {store.context.sellable && store.context.payable ? (
        <>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <div className="switch-toggle switch-3 switch-candy">
              {store.context.config.periods.indexOf('monthly') > -1 && (
                <>
                  <input
                    id="monthly"
                    value="monthly"
                    name="state-d"
                    type="radio"
                    checked={store.period === 'monthly'}
                    onChange={handlePeriodChange}
                  />
                  <label htmlFor="monthly" className="label-payment">
                    {t('period.month', { amount: parseFloat(store.context.config.prices.monthly[0]) })}
                  </label>
                </>
              )}

              {store.context.config.periods.indexOf('yearly') > -1 && (
                <>
                  <input
                    id="yearly"
                    value="yearly"
                    name="state-d"
                    type="radio"
                    checked={store.period === 'yearly'}
                    onChange={handlePeriodChange}
                  />
                  <label htmlFor="yearly" className="label-payment">
                    {t('period.year', { amount: parseFloat(store.context.config.prices.yearly[0]) })}
                  </label>
                </>
              )}
            </div>
          </div>

          <div
            style={{
              marginTop: '1em',
              marginBottom: '1em',
            }}
          >
            <Button
              primary
              fluid
              as={Link}
              to={`${getBasePath(location)}/checkout`}
              onClick={() => {
                store.trackEventPremium('conversion');
              }}
            >
              {store.context.metadata.trial?.hasTrial && store.context.metadata.trial?.trialDuration ? t('start_trial', { days: store.context.metadata.trial.trialDuration }) : t('interested')}
            </Button>
          </div>

          <div className="premium-other">
            <Link
              as="span"
              className="premium-already-member"
              to={`${getBasePath(location)}/login`}
            >
              {t('already_client')}
            </Link>
          </div>
        </>
      ) : (
        <div className="premium-other">
          <div className="premium-other">
            <Link
              as="span"
              className="premium-already-member"
              to={`${getBasePath(location)}/login`}
            >
              {t('already_client')}
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferSelectionComponent;
