/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Button, Input, Icon } from 'semantic-ui-react';
import { notify } from 'react-notify-toast';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  alreadyEmail, alreadyCode, getUserInfos, alreadyCodeSubscription,
} from '../stripe/index';
import Store from '../store';
import getBasePath from '../helpers/getBasePath';

const AlreadyPaid = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState();
  const [btnCode, setBtnCode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnNewCode, setBtnNewCode] = useState(null);
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [tid, setTid] = useState(null);

  const store = Store.useContainer();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateEmail = (e, { value }) => emailRegex.test(value);

  const handleSubmitEmail = async () => {
    setLoading(true);
    if (emailError === true) {
      return;
    }
    if (!emailError) {
      const res = await alreadyEmail(store.context.slug, {
        pid: store.context.pid,
        email,
        tid: store.context.tid,
      });

      if (res.status === 'ERROR') {
        switch (res.error) {
          case 'FORBIDDEN_EMAIL':
            notify.show(
              t('login.error.forbidden'),
              'error',
            );
            break;

          case 'INVALID_EMAIL':
            notify.show(t('login.error.invalid_email'), 'error');
            break;

          default:
            notify.show(
              t('login.error.unknown', { email: store.context.metadata.supportEmail }),
              'error',
            );
            break;
        }
      } else {
        setEmailError(false);
        setIsSubscribe(res.state === 'SUBSCRIBE');

        if (res.tid) {
          setTid(res.tid);
        }
      }
    }
    setBtnCode(true);
    setLoading(false);
    setBtnNewCode(!btnNewCode);
  };

  const handleKeyPressEmail = (e) => {
    if (e.charCode === 32 || e.charCode === 13) {
      e.preventDefault();
      handleSubmitEmail();
    }
  };

  const handleSubmitCode = async () => {
    setLoading(true);
    const res = isSubscribe ? await alreadyCodeSubscription(store.context.slug, {
      pid: store.context.pid,
      email,
      code: (code || '').trim(),
      tid: tid || store.context.tid,
      optin: false,
    }) : await alreadyCode(store.context.slug, {
      pid: store.context.pid,
      email,
      code: (code || '').trim(),
    });

    if (res.status === 'ERROR') {
      switch (res.error) {
        case 'FORBIDDEN_EMAIL':
          notify.show(
            t('login.error.forbidden'),
            'error',
          );
          break;

        case 'INVALID_CODE':
          notify.show(t('login.error.invalid_code'), 'error');
          break;

        default:
          notify.show(
            t('login.error.unknown', { email: store.context.metadata.supportEmail }),
            'error',
          );
          break;
      }
    } else {
      store.setContext((ctx) => ({
        ...ctx,
        feed: res.feed,
        feedToken: res.token,
      }));
      localStorage.setItem(`token_${store.context.pid}`, res.token);
      store.setUserPremium(await getUserInfos(store.context.slug, res.token));

      store.trackEventPremium('login');
      history.replace(`${getBasePath(location)}/account`);
    }
    setLoading(false);
  };

  const handleKeyPressCode = (e) => {
    if (e.charCode === 32 || e.charCode === 13) {
      e.preventDefault();
      handleSubmitCode();
    }
  };

  const toggleCode = () => {
    setBtnCode(!btnCode);
  };

  return (
    <>
      <div className="connexion">
        <div style={{ width: '100%', textAlign: 'left' }}>
          <Link
            to={`${getBasePath(location)}/`}
          >
            <Icon name="left arrow" />
            {t('login.back')}
          </Link>
        </div>

        <h2 className="alreadyPaidTitle" style={{ fontSize: '14px' }}>
          {t('login.explain_1')}
        </h2>
        <p style={{ fontSize: '14px' }}>
          {t('login.explain_2')}
        </p>

        <div className="container-input-already">
          <Input
            size="large"
            placeholder={t('login.form_email')}
            disabled={btnCode === true}
            fluid
            icon="at"
            iconPosition="left"
            error={emailError}
            value={email}
            style={{ marginBottom: '5px', width: '100%', height: '38px' }}
            onKeyPress={handleKeyPressEmail}
            onChange={(evt, dt) => {
              setEmail(dt.value.trim().replace(/(\r\n|\n|\r)/gm, ''));
              setEmailError(!validateEmail(evt, dt) || dt.value === '');
            }}
          />

          {btnCode === true ? (
            <Button style={{ height: '38px' }} onClick={toggleCode}>
                X
            </Button>
          ) : null}
        </div>

        {btnCode && (
        <>
          <p
            style={{
              fontSize: '14px',
              marginTop: '1.2em',
              marginBottom: '1.2em',
            }}
          >
            {t('login.explain_code')}
          </p>
          <Input
            placeholder={t('login.form_code')}
            fluid
            icon="lock"
            iconPosition="left"
            error={codeError}
            value={code}
            style={{ marginBottom: '3em' }}
            onKeyPress={handleKeyPressCode}
            onChange={(evt, dt) => {
              setCode(dt.value.trim().replace(/(\r\n|\n|\r)/gm, ''));
              setCodeError(dt.value === '');
            }}
          />
        </>
        )}

        <div className="buttons">
          {!btnCode && (
          <div className="btn-form">
            <Button
              disabled={
                !!(loading || !emailError === false || email === '')
              }
              loading={loading}
              fluid
              primary
              onClick={handleSubmitEmail}
              onKeyPress={handleKeyPressEmail}
            >
              {t('login.validate_email')}
            </Button>
          </div>
          )}

          {btnCode && (
          <div className="connexion-btn">
            <Button
              primary
              fluid
              onClick={handleSubmitCode}
              disabled={loading || code === ''}
              loading={loading}
            >
              {t('login.validate_code')}
            </Button>
            {btnNewCode && (
            <p
              style={{ color: 'black', paddingTop: '15px', cursor: 'pointer' }}
              onClick={handleSubmitEmail}
            >
              {t('login.retry_code')}
            </p>
            )}
          </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AlreadyPaid;
