/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  List, Image, Dimmer, Loader, Icon,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { notify } from 'react-notify-toast';
import { API } from '../../constants';
import Store from '../../store/index';
import PremiumUserListen from './PremiumUserListen';

const PremiumUserOffers = () => {
  const [t] = useTranslation();
  const store = Store.useContainer();

  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('LIST');
  const [feed, setFeed] = useState(null);
  const [podcast, setPodcast] = useState(null);

  const getFeed = async (slug) => {
    setLoading(true);
    const hp = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${store.context.feedToken}`,
      },
      method: 'GET',
    };

    try {
      const res = await fetch(
        `${API}/subscription/${slug}/feed`,
        hp,
      );
      const remoteFeed = await res.json();
      if (remoteFeed.status === 'SUCCESS') {
        setFeed(remoteFeed.data);
        setStep('FEED');
      } else {
        notify.show(
          t('user.error', { email: store.context.metadata.supportEmail }),
          'error',
        );
      }
    } catch (e) {
      notify.show(
        t('user.error', { email: store.context.metadata.supportEmail }),
        'error',
      );
    }
    setLoading(false);
  };

  if (store.spotifyUri) {
    setTimeout(() => {
      window.location.href = `${API}/s/${store.context.slug}/spotify?token=${store.context.feedToken}`;
    }, 500);
    return (
      <div className="subscribe-container">
        <Dimmer.Dimmable dimmed style={{ minHeight: '200px', backgroundColor: 'transparent' }}>
          <Dimmer active inverted style={{ minHeight: '200px', backgroundColor: 'transparent' }}>
            <Loader active>{t('user.redirect_to_spotify')}</Loader>
          </Dimmer>
        </Dimmer.Dimmable>
      </div>
    );
  }

  return step === 'LIST' ? (
    <div className="subscribe-container">
      <Dimmer.Dimmable dimmed={loading}>
        <div style={{ width: '100%', padding: '20px' }}>
          <h3 style={{ paddingTop: 0 }}>
            {t('user.listen_to_podcasts')}
          </h3>
          <List
            selection
            verticalAlign="middle"
            style={{ paddingLeft: '20px', paddingRight: '20px' }}
          >
            {(store.userPremium?.user?.players || []).filter(
              (p) => p.offer !== store.context.bundle?.slug,
            ).sort((e1, e2) => e1.name.localeCompare(e2.name)).map((p) => (
              <List.Item
                key={p.podcast}
                as="a"
                onClick={() => {
                  setPodcast(p);
                  getFeed(p.offer);
                }}
                style={{ textAlign: 'left', display: 'flex', flexDirection: 'line', alignItems: 'center' }}
              >
                <Image
                  style={{ width: '32px' }}
                  src={p.image}
                />
                <List.Content style={{ width: '100%' }}>
                  <List.Header style={{ outline: 'none !important' }}>
                    {p.name}
                  </List.Header>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </div>
        <Dimmer active={loading} inverted>
          <Loader active={loading} />
        </Dimmer>
      </Dimmer.Dimmable>
    </div>
  ) : (
    <>
      <PremiumUserListen
        feedName={podcast.name}
        feedToken={store.context.feedToken}
        feed={feed?.feed}
        playerVar={feed?.player}
        spotifyShowUri={feed?.spotifyShowUri}
      />

      <div textAlign="center">
        <a href="#" onClick={() => setStep('LIST')}>
          <Icon name="left arrow" />
          {t('user.back_to_list')}
        </a>
      </div>

    </>
  );
};

export default PremiumUserOffers;
