/* eslint-disable no-useless-escape */
/* eslint-disable no-empty */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';

import {
  List, Image, Loader, Dimmer,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import { useTranslation } from 'react-i18next';

import { notify } from 'react-notify-toast';

import Store from '../../store/index';

import { API } from '../../constants';

import '../../App.scss';
import PremiumModalPodcast from './PremiumModalPodcast';

const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <https://bit.ly/TJjs1V>
    const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
  }
  return null;
};

const isMobile = () => {
  const a = navigator.userAgent || navigator.vendor || window.opera;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od|ad)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a,
    )
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4),
    )
  ) { return true; }
  return false;
};

const PremiumUserListen = ({
  feed, feedToken, playerVar, feedName, spotifyShowUri,
}) => {
  const [t] = useTranslation();

  const store = Store.useContainer();

  const [step, setStep] = useState('SUBSCRIBE');
  const [isApple, setIsApple] = useState(false);
  const [loading, setLoading] = useState(false);

  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const version = iOSversion();

  const getPrefixedFeed = (prefix) => `${prefix}://${feed.replace('http://', '').replace('https://', '')}`;

  const getSpotifyLink = (oid) => `${API}/s/${store.context.slug}/spotify?token=${feedToken}&oid=${oid}`;

  const getYTLink = () => `https://music.youtube.com/library/podcasts?addrssfeed=${encodeURIComponent(feed)}`;

  const canShow = (name) => !(store.context?.metadata?.excludedApps || []).includes(name);

  const handleClick = (isApp) => {
    setLoading(true);
    setStep('CLICKED');
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    setIsApple(isApp);
  };

  if (store.spotifyUri) {
    setTimeout(() => {
      window.location.href = `${API}/s/${store.context.slug}/spotify?token=${store.context.feedToken}`;
    }, 500);
    return (
      <div className="subscribe-container">
        <Dimmer.Dimmable dimmed style={{ minHeight: '200px', backgroundColor: 'transparent' }}>
          <Dimmer active inverted style={{ minHeight: '200px', backgroundColor: 'transparent' }}>
            <Loader active>{t('user.redirect_to_spotify')}</Loader>
          </Dimmer>
        </Dimmer.Dimmable>
      </div>
    );
  }

  return (
    <div className="subscribe-container">
      <div className="subscribe">
        {!isMobile() && (
          <>
            <p className="steps">
              {t('listen.desktop_1')}
              <span style={{ fontWeight: '900', textDecoration: 'underline' }}>
                {t('listen.desktop_2')}
              </span>
              .
            </p>
            <List
              selection
              verticalAlign="middle"
              style={{ paddingLeft: '40px', paddingRight: '40px' }}
            >
              { spotifyShowUri && canShow('Spotify') && (
                <List.Item
                  href={getSpotifyLink(!store.spotifyUri ? spotifyShowUri : '')}
                  as="a"
                  onClick={() => handleClick(false)}
                  style={{ textAlign: 'left' }}
                >
                  <Image
                    style={{ width: '32px' }}
                    src="https://static.audiomeans.fr/podcasts/Spotify.png"
                  />
                  <List.Content>
                    <List.Header style={{ outline: 'none !important' }}>
                      Spotify
                    </List.Header>
                  </List.Content>
                </List.Item>
              )}

              {!store.spotifyUri && (
                <>
                  { canShow('Audiomeans') && (
                    <List.Item
                      style={{ display: 'flex', textAlign: 'left' }}
                      as="a"
                    >
                      <PremiumModalPodcast playerVar={playerVar} />
                    </List.Item>
                  )}

                  { isMac && canShow('Apple Podcasts') && (
                    <List.Item
                      as="a"
                      href={getPrefixedFeed('podcast')}
                      style={{ textAlign: 'left' }}
                    >
                      <Image
                        style={{ width: '32px' }}
                        src="https://static.audiomeans.fr/podcasts/Apple-Podcasts.png"
                      />
                      <List.Content>
                        <List.Header style={{ outline: 'none !important' }}>
                          Apple Podcasts
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )}
                </>
              )}
            </List>
          </>
        )}

        {isMobile() && (step === 'SUBSCRIBE' || loading) && (
          <Dimmer.Dimmable dimmed={loading}>
            <h3 style={{ paddingTop: 0 }}>
              {t('listen.mobile', { feed: feedName })}
            </h3>
            {store.context.metadata.validationText
            && store.context.metadata.validationText !== '' ? (
              <div className="validation-container">
                {ReactHtmlParser(store.context.metadata.validationText)}
              </div>
              ) : null}
            <List
              selection
              verticalAlign="middle"
              style={{ paddingLeft: '40px', paddingRight: '40px' }}
            >
              {!store.spotifyUri && (
                <>
                  { spotifyShowUri && canShow('Spotify') && (
                    <List.Item
                      href={getSpotifyLink(!store.spotifyUri ? spotifyShowUri : '')}
                      as="a"
                      onClick={() => handleClick(false)}
                      style={{ textAlign: 'left' }}
                    >
                      <Image
                        style={{ width: '32px' }}
                        src="https://static.audiomeans.fr/podcasts/Spotify.png"
                      />
                      <List.Content>
                        <List.Header style={{ outline: 'none !important' }}>
                          Spotify
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )}

                  {!navigator.userAgent.match(/android/i) && canShow('Apple Podcasts') && (
                    <List.Item
                      as="a"
                      href={
                        version && version.length > 0 && version[0] >= 15
                          ? getPrefixedFeed('podcast')
                          : '#'
                      }
                      onClick={() => {
                        try {
                          if (
                            version
                            && version.length > 0
                            && version[0] >= 15
                          ) {
                            handleClick(true);
                            return;
                          }
                        } catch {}

                        setStep('APPLE');
                      }}
                      style={{ textAlign: 'left' }}
                    >
                      <Image
                        style={{ width: '32px' }}
                        src="https://static.audiomeans.fr/podcasts/Apple-Podcasts.png"
                      />
                      <List.Content>
                        <List.Header style={{ outline: 'none !important' }}>
                          Apple Podcasts
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )}

                  { canShow('Pocket Casts') && (
                    <List.Item
                      as="a"
                      onClick={async (evt) => {
                        evt.preventDefault();

                        setLoading(true);
                        const hp = {
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${feedToken}`,
                          },
                          method: 'POST',
                          body: JSON.stringify({
                            feed,
                          }),
                        };

                        try {
                          const res = await fetch(
                            `${API}/subscription/podcast/${store.context.pid}/pc`,
                            hp,
                          );

                          const share = await res.json();
                          if (share.status === 'SUCCESS') {
                            window.location.href = share.data;
                          } else {
                            notify.show(
                              t('listen.error', { email: store.context.metadata.supportEmail }),
                              'error',
                            );
                          }
                        } catch (e) {
                          notify.show(
                            t('listen.error', { email: store.context.metadata.supportEmail }),
                            'error',
                          );
                        }
                        setLoading(false);
                      }}
                      style={{ textAlign: 'left' }}
                    >
                      <Image
                        style={{ width: '32px' }}
                        src="https://static.audiomeans.fr/podcasts/PocketCasts.png"
                      />
                      <List.Content>
                        <List.Header style={{ outline: 'none !important' }}>
                          PocketCasts
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )}

                  {!navigator.userAgent.match(/(iphone|ipad)/i) && canShow('PodcastAddict') && (
                    <List.Item
                      href={getPrefixedFeed('podcastaddict')}
                      onClick={() => handleClick(false)}
                      as="a"
                      style={{ textAlign: 'left' }}
                    >
                      <Image
                        style={{ width: '32px' }}
                        src="https://static.audiomeans.fr/podcasts/Podcast-Addict.png"
                      />
                      <List.Content>
                        <List.Header style={{ outline: 'none !important' }}>
                          PodcastAddict
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )}

                  {/* {canShow('youtubeMusic') && (
                    <List.Item
                      href={getYTLink()}
                      onClick={() => handleClick(false)}
                      as="a"
                      style={{ textAlign: 'left' }}
                    >
                      <Image
                        style={{ width: '32px' }}
                        src="https://static.audiomeans.fr/podcasts/youtube-music.png"
                      />
                      <List.Content>
                        <List.Header style={{ outline: 'none !important' }}>
                          Youtube Music
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )} */}

                  { canShow('Downcast') && (
                    <List.Item
                      href={getPrefixedFeed('downcast')}
                      as="a"
                      onClick={() => handleClick(false)}
                      style={{ textAlign: 'left' }}
                    >
                      <Image
                        style={{ width: '32px' }}
                        src="https://static.audiomeans.fr/podcasts/Downcast.png"
                      />
                      <List.Content>
                        <List.Header style={{ outline: 'none !important' }}>
                          Downcast
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )}

                  {!navigator.userAgent.match(/(iphone|ipad)/i) && canShow('PodcastRepublic') && (
                    <List.Item
                      href={getPrefixedFeed('podcastrepublic')}
                      as="a"
                      onClick={() => handleClick(false)}
                      style={{ textAlign: 'left' }}
                    >
                      <Image
                        style={{ width: '32px' }}
                        src="https://static.audiomeans.fr/podcasts/PodcastRepublic.png"
                      />
                      <List.Content>
                        <List.Header style={{ outline: 'none !important' }}>
                          Podcast Republic
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )}

                  { canShow('Overcast') && (
                    <List.Item
                      onClick={async (evt) => {
                        evt.preventDefault();

                        setLoading(true);
                        const hp = {
                          headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${feedToken}`,
                          },
                          method: 'POST',
                          body: JSON.stringify({
                            feed,
                          }),
                        };

                        try {
                          const res = await fetch(
                            `${API}/subscription/podcast/${store.context.pid}/oc`,
                            hp,
                          );

                          const share = await res.json();
                          if (share.status === 'SUCCESS') {
                            window.location.href = share.data;
                          } else {
                            notify.show(
                              t('listen.error', { email: store.context.metadata.supportEmail }),
                              'error',
                            );
                          }
                        } catch (e) {
                          notify.show(
                            t('listen.error', { email: store.context.metadata.supportEmail }),
                            'error',
                          );
                        }
                        setLoading(false);
                      }}
                      as="a"
                      style={{ textAlign: 'left' }}
                    >
                      <Image
                        style={{ width: '32px' }}
                        src="https://static.audiomeans.fr/podcasts/Overcast.png"
                      />
                      <List.Content>
                        <List.Header style={{ outline: 'none !important' }}>
                          Overcast
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  )}
                </>
              )}

              {!store.spotifyUri && canShow('Audiomeans') && (
                <List.Item
                  style={{ display: 'flex', textAlign: 'left' }}
                  as="a"
                >
                  <PremiumModalPodcast playerVar={playerVar} />
                </List.Item>
              )}
            </List>
            <Dimmer active={loading} inverted>
              <Loader active={loading} />
            </Dimmer>
          </Dimmer.Dimmable>
        )}

        {step === 'APPLE' && !loading && (
          <>
            <h5 style={{ marginTop: 0, fontWeight: 'normal' }}>
              {t('listen.apple.explain_1')}
            </h5>
            <h5 style={{ marginTop: 0, fontWeight: 'normal' }}>
              {t('listen.apple.explain_2')}
            </h5>
            <h5 style={{ marginTop: 0 }}>
              {t('listen.apple.explain_3')}
            </h5>

            <List selection verticalAlign="middle">
              <List.Item
                as="a"
                href={getPrefixedFeed('podcast')}
                style={{ textAlign: 'left' }}
              >
                <Image
                  style={{ width: '32px' }}
                  src="https://static.audiomeans.fr/podcasts/Apple-Podcasts.png"
                />
                <List.Content>
                  <List.Header style={{ outline: 'none !important' }}>
                    {t('listen.apple.add')}
                  </List.Header>
                </List.Content>
              </List.Item>
            </List>

            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setStep('SUBSCRIBE');
              }}
            >
              {t('listen.back')}
            </a>
          </>
        )}

        {step === 'CLICKED' && !loading && (
          <>
            <h5>Merci !</h5>
            <h5 style={{ marginTop: 0 }}>
              {t('listen.apple.validate_1')}
            </h5>

            {isApple && (
              <h5>
                {t('listen.apple.validate_1')}
              </h5>
            )}

            {!isApple && <h5>{t('listen.apple.close')}</h5>}

            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setStep('SUBSCRIBE');
              }}
            >
              {t('listen.back')}
            </a>
          </>
        )}
      </div>
    </div>
  );
};

PremiumUserListen.propTypes = {
  feed: PropTypes.string,
  feedToken: PropTypes.string,
  playerVar: PropTypes.shape({

  }),
  feedName: PropTypes.string,
  spotifyShowUri: PropTypes.string,
};

PremiumUserListen.defaultProps = {
  feed: null,
  feedToken: null,
  playerVar: {},
  feedName: '',
  spotifyShowUri: null,
};

export default PremiumUserListen;
