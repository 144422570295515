import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie-player';

import lottieJson from '../animations/lottie.json';
import checkJson from '../animations/check.json';
import failedJson from '../animations/failed.json';
import { checkPayment, getUserInfos } from '../stripe';

import Store from '../store';
import getBasePath from '../helpers/getBasePath';

const PaymentChecker = () => {
  const [t] = useTranslation();

  const store = Store.useContainer();
  const history = useHistory();
  const location = useLocation();

  const [ended, setEnded] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleChecker = useCallback(async () => {
    const {
      status, token, error, isGift,
    } = await checkPayment(store.context.slug);

    if (ended) {
      return;
    }

    if (status === 'SUCCESS' && token) {
      setEnded(true);

      store.resetForm();
      if (!isGift) {
        store.setContext((ctx) => ({
          ...ctx,
          feed: '',
          feedToken: token,
        }));
        localStorage.setItem(`token_${store.context.pid}`, token);
        store.setUserPremium(await getUserInfos(store.context.slug, token));
      }

      setIsSuccess(true);
      setTimeout(() => {
        if (isGift) {
          history.push(`${getBasePath(location)}/thankyou`);
        } else {
          history.push(`${getBasePath(location)}/account`);
        }
      }, 2000);

      return;
    }

    if (status === 'ERROR' && error === 'FORBIDDEN') {
      notify.show(
        t('payment.error.unknown_session'),
        'error',
      );

      setTimeout(() => {
        history.push(`${getBasePath(location)}/`);
      }, 2000);
    }

    if (status === 'ERROR' && error === 'FAILED') {
      setIsError(true);
    }
  }, [
    history,
    location,
    store,
    t,
    ended,
  ]);

  useEffect(() => {
    if (isError) {
      return;
    }

    const intervalCall = setInterval(() => {
      handleChecker();
    }, 2000);
    // eslint-disable-next-line consistent-return
    return () => {
      clearInterval(intervalCall);
    };
  }, [handleChecker, isError]);

  if (!isError && !isSuccess) {
    return (
      <div style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'whitesmoke',
        zIndex: '999999',
      }}
      >
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ width: 250, height: 250 }}
        />
        <div className="payment-loading">{t('payment.check')}</div>
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'whitesmoke',
        zIndex: '999999',
      }}
      >
        <Lottie
          loop={false}
          animationData={checkJson}
          play
          style={{ width: 125, height: 125 }}
        />
      </div>
    );
  }

  if (isError) {
    return (
      <div style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: 'whitesmoke',
        zIndex: '999999',
      }}
      >
        <Lottie
          loop={false}
          animationData={failedJson}
          play
          style={{ width: 125, height: 125 }}
        />
        <div className="payment-failed">{t('payment.check_fail')}</div>
        <div
          style={{
            marginTop: '4em',
            marginBottom: '1em',
          }}
        >
          <Button
            primary
            fluid
            as={Link}
            to={`${getBasePath(location)}/checkout`}
          >
            {t('payment.check_retry')}
          </Button>
        </div>
      </div>
    );
  }

  return (<></>);
};

export default PaymentChecker;
