import { API } from '../constants';
import packageJson from '../../package.json';

const getHeaders = () => ({
  'x-client-version': packageJson.version,
});

const apiCaller = async (url, params, method = 'POST', extraHeaders = {}) => {
  const hp = {
    headers: {
      ...getHeaders(),
      ...extraHeaders,
      ...(method !== 'GET' ? { 'Content-Type': 'application/json' } : {}),
    },
    ...(params ? { body: JSON.stringify(params) } : {}),
    method: method || 'POST',
    credentials: 'include',
  };

  const response = await fetch(url, hp);
  const dt = await response.json();

  return dt;
};

const retrieveOffer = async (url) => apiCaller(url, null, 'GET');

const validateEmail = async (slug, email) => apiCaller(`${API}/p/${slug}/payment/email`, { email }, 'POST');

const processLogin = async (params) => {
  const url = `${API}/subscription/login`;
  return apiCaller(url, params, 'POST');
};

const processEmail = async (params, slug) => {
  const url = `${API}/subscription/${slug ? `${slug}/` : ''}email`;
  return apiCaller(url, params, 'POST');
};

const processPromoCode = async (params, slug) => {
  const url = `${API}/subscription/${slug ? `${slug}/` : ''}promo-code`;
  return apiCaller(url, params, 'POST');
};

const processEmailFile = async (params, slug) => {
  const url = `${API}/subscription/${slug ? `${slug}/` : ''}content`;
  return apiCaller(url, params, 'POST');
};

const processCode = async (params) => {
  const url = `${API}/subscription/email/code`;
  return apiCaller(url, params, 'POST');
};

const alreadyEmail = async (slug, params) => {
  const url = `${API}/s/${slug}/already/email`;
  return apiCaller(url, params, 'POST');
};

const alreadyCode = async (slug, params) => {
  const url = `${API}/s/${slug}/already/email/code`;
  return apiCaller(url, params, 'POST');
};

const alreadyCodeSubscription = async (slug, params) => {
  const url = `${API}/s/${slug}/email/code`;
  return apiCaller(url, params, 'POST');
};

const getUserInfos = async (slug, token) => {
  const url = `${API}/s/${slug}/user`;
  return apiCaller(url, null, 'GET', {
    Authorization: `Bearer ${token}`,
  });
};

const doUnsubscribe = async (slug, token, tid) => {
  const url = `${API}/s/${slug}/user/unsubscribe`;
  return apiCaller(url, { tid }, 'POST', {
    Authorization: `Bearer ${token}`,
  });
};

const processSubscription = async (stripe, ev, params) => {
  const {
    amount, period, currency, slug, optin, promoCode,
  } = params;

  const {
    payerEmail, payerName, payment_method: paymentMethod, code, payerCountry,
  } = ev;

  const am = parseInt(amount * 100, 10);

  const url = `${API}/p/${slug}/payment/subscribe`;
  const data = {
    amount: am,
    period,
    currency,
    ev: {
      payerName,
      payerEmail,
      payerCountry,
      payerFirstname: ev.payerFirstname,
      payerLastname: ev.payerLastname,
      code,
    },
    paymentMethod,
    optin,
    promoCode,
  };

  const hp = {
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: 'POST',
    credentials: 'include',
  };

  const response = await fetch(url, hp);
  const dt = await response.json();

  if (dt.status === 'ERROR') {
    return dt;
  }

  if (code) {
    return {
      status: 'SUCCESS',
    };
  }

  const confirmResult = await stripe.confirmCardSetup(
    dt.token,
    { payment_method: ev.payment_method, return_url: `${API}/${dt.returnUrl}?curl=${encodeURIComponent(window.location.href)}` },
    { handleActions: false },
  );

  if (confirmResult.error) {
    if (typeof ev.complete !== 'undefined') {
      ev.complete('fail');
    } else {
      throw new Error(confirmResult);
    }
  }

  if (confirmResult.setupIntent.status === 'requires_action') {
    window.location.href = confirmResult.setupIntent.next_action.redirect_to_url.url;
    return {
      status: 'REDIRECT',
    };
  }

  return {
    status: 'SUCCESS',
  };
};

const round = (value, precision) => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

const processOneTimePayment = async (stripe, ev, params) => {
  const {
    amount, currency, optin, promoCode, gift, giftPeriod, giftAmount, slug,
  } = params;

  const am = parseInt(Math.round(round(amount, 2) * 10 ** 2), 10);
  const giftAm = giftAmount ? parseInt(Math.round(round(giftAmount, 2) * 10 ** 2), 10) : null;

  const url = `${API}/p/${slug}/payment/init`;
  const data = {
    amount: am,
    gift,
    giftAmount: giftAm,
    giftPeriod,
    currency,
    optin,
    promoCode,
    ev: {
      payerEmail: ev.payerEmail,
      payerName: ev.payerName,
      payerFirstname: ev.payerFirstname,
      payerLastname: ev.payerLastname,
      payerCountry: ev.payerCountry,
      payment_method: ev.payment_method || null,
      code: ev.code || null,
    },
  };
  const hp = {
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    method: 'POST',
    credentials: 'include',
  };

  const response = await fetch(url, hp);
  const dt = await response.json();

  if (ev.code && dt.status === 'SUCCESS' && dt.token) {
    return dt;
  }

  if (dt.status === 'ERROR') {
    return dt;
  }

  const confirmResult = await stripe.confirmCardPayment(
    dt.token,
    { payment_method: ev.payment_method, return_url: `${API}/${dt.returnUrl}?curl=${encodeURIComponent(window.location.href)}` },
    { handleActions: false },
  );

  if (confirmResult.error) {
    if (typeof ev.complete !== 'undefined') {
      ev.complete('fail');
    } else {
      throw new Error(confirmResult);
    }
  }

  if (confirmResult.paymentIntent.status === 'requires_action') {
    window.location.href = confirmResult.paymentIntent.next_action.redirect_to_url.url;
    return {
      status: 'REDIRECT',
    };
  }

  return {
    status: 'SUCCESS',
  };
};

const checkPayment = async (slug) => {
  const url = `${API}/p/${slug}/payment/check`;
  const hp = {
    headers: {
      ...getHeaders(),
    },
    method: 'GET',
    credentials: 'include',
  };

  const response = await fetch(url, hp);
  const dt = await response.json();

  return dt;
};

const processPayment = async (stripe, ev, params) => {
  const { gift, period } = params;
  try {
    const d = (period !== 'onetime' && !gift) ? (
      await processSubscription(stripe, ev, params)
    ) : (
      await processOneTimePayment(stripe, ev, params)
    );

    if (typeof ev.complete !== 'undefined') {
      await ev.complete('success');
    }

    return d;
  } catch (e) {
    if (typeof ev.complete !== 'undefined') {
      ev.complete('fail');
    } else {
      return {
        status: 'ERROR',
        error: 'PAYMENT_FAILED',
      };
    }
  }

  return null;
};

const setupIntent = async (oid, slug, token) => {
  const url = `${API}/s/${slug}/user/setup-payment`;
  return apiCaller(url, { oid }, 'POST', {
    Authorization: `Bearer ${token}`,
  });
};

const updatePayment = async (oid, paymentMethod, slug, token) => {
  const url = `${API}/s/${slug}/user/update-payment`;
  return apiCaller(url, { oid, paymentMethod }, 'POST', {
    Authorization: `Bearer ${token}`,
  });
};

export {
  processPayment,
  processLogin,
  processEmail,
  processCode,
  alreadyEmail,
  alreadyCode,
  alreadyCodeSubscription,
  getUserInfos,
  doUnsubscribe,
  setupIntent,
  updatePayment,
  processEmailFile,
  processPromoCode,
  checkPayment,
  retrieveOffer,
  validateEmail,
};
