import React from 'react';
import { Tab, Card, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import Store from '../../store/index';

import formatDate from '../../helpers/formatDate';

const PremiumUserInvoices = () => {
  const [t] = useTranslation();
  const store = Store.useContainer();

  return (
    <>
      <Tab.Pane as="div">
        <Card.Group className="offer-list">
          {store.userPremium.invoices.map((inv) => (
            <Card key={inv.number} color="teal">
              <Card.Content>
                <Icon
                  onClick={() => window.open(inv.url)}
                  style={{
                    position: 'absolute',
                    right: 0,
                    marginTop: '0.15em',
                  }}
                  size="huge"
                  name="file pdf"
                  color="#1a78c2"
                />
                <Card.Header
                  style={{ margin: '0' }}
                >
                  {`${inv.number}`}

                </Card.Header>
                <Card.Description style={{ fontSize: '1.2em' }}>
                  {inv.period_start !== inv.period_end ? (
                    <>
                      {t('user.invoice.from_to', {
                        start: formatDate(
                          new Date(parseInt(inv.period_start, 10) * 1000),
                        ),
                        end: formatDate(
                          new Date(parseInt(inv.period_end, 10) * 1000),
                        ),
                      })}
                    </>
                  ) : (
                    <>
                      {t('user.invoice.of', {
                        date: formatDate(
                          new Date(parseInt(inv.period_start, 10) * 1000),
                        ),
                      })}
                    </>
                  )}
                </Card.Description>
                <Card.Description style={{ fontSize: '1.2em' }}>
                  <Icon name="euro sign" />
                  {(inv.total / 100).toFixed(2)}
                </Card.Description>
              </Card.Content>
            </Card>
          ))}
        </Card.Group>
      </Tab.Pane>
    </>
  );
};

export default PremiumUserInvoices;
