/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import { Menu, Icon, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import PremiumUserInvoices from './PremiumUserInvoices';
import PremiumUserListen from './PremiumUserListen';

import PremiumCBUpdate from './PremiumCBUpdate';

import Store from '../../store';
import { doUnsubscribe } from '../../stripe/index';
import PremiumUserOffers from './PremiumUserOffers';
import getBasePath from '../../helpers/getBasePath';

function PremiumUserAccount() {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [activeItem, setActiveItem] = useState('listen');
  const [toggleUserAccount, setToggleUserAccount] = useState(null);
  const [toggleUserUnsubscribe, setToggleUserUnsubscribe] = useState(null);

  const store = Store.useContainer();

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
  };

  const toggleBtnAccount = () => {
    setToggleUserAccount(!toggleUserAccount);
    setToggleUserUnsubscribe(false);
  };

  const toggleBtnUnsubscribe = () => {
    setToggleUserUnsubscribe(!toggleUserUnsubscribe);
    setToggleUserAccount(false);
  };

  useEffect(() => {
    if (!store.userPremium?.user) {
      history.replace(`${getBasePath(location)}`);
    }
  }, [history, location, store.userPremium.user]);

  const disconnectPremium = () => {
    store.setContext((ctx) => ({
      ...ctx,
      feed: null,
      feedToken: null,
    }));
    localStorage.removeItem(`token_${store.context.pid}`);
    store.setUserPremium(null);
    store.setSession(new Date().getTime());
    notify.show(t('user.disconnect.success'), 'success');

    store.trackEventPremium('logout');

    history.replace(`${getBasePath(location)}`);
  };

  const isStream = store.context.slug.substr(0, 1) === 'S';
  const { invoices } = store.userPremium || {};
  const hasInvoices = (invoices || []).length > 0;

  const stylePackFooter = {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    position: 'absolute',
    bottom: '0',
    left: '0',
  };

  const userUnsubscribe = async () => {
    const token = localStorage.getItem(`token_${store.context.pid}`);
    const { tid } = store.userPremium?.user || {};

    const res = await doUnsubscribe(store.context.slug, token, tid);

    if (res.status === 'SUCCESS') {
      store.setContext((ctx) => ({
        ...ctx,
        feed: null,
        feedToken: null,
      }));
      localStorage.removeItem(`token_${store.context.pid}`);
      store.setUserPremium({});
      store.setSession(new Date().getTime());
      notify.show(t('user.unsubscribe.success'), 'success');
      history.replace(`${getBasePath(location)}`);
    } else {
      notify.show(
        t('user.unscubscribe.error'),
        'error',
      );
    }
  };

  return (
    <>
      <Notifications />

      <div className="container-premium-area">
        <div style={{ width: '100%' }}>
          <div className="menu-nav">
            <Menu attached="top" tabular>
              <Menu.Item
                name="account"
                style={
                  !hasInvoices
                    ? {
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                    }
                    : { width: '33%', justifyContent: 'center' }
                }
                active={activeItem === 'account'}
                onClick={handleItemClick}
              >
                <Icon name="user" />
                {' '}
                {t('user.account')}
              </Menu.Item>

              {hasInvoices && (
                <Menu.Item
                  name="invoices"
                  active={activeItem === 'invoices'}
                  onClick={handleItemClick}
                  style={{ width: '33%', justifyContent: 'center' }}
                >
                  <Icon name="file alternate outline" />
                  {' '}
                  {t('user.invoices')}
                </Menu.Item>
              )}

              <Menu.Item
                name="listen"
                style={
                  !hasInvoices
                    ? {
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                    }
                    : { width: '33%', justifyContent: 'center' }
                }
                active={activeItem === 'listen'}
                onClick={handleItemClick}
              >
                <Icon name="rss" />
                {' '}
                {t('user.listen')}
              </Menu.Item>
            </Menu>
          </div>

          {activeItem === 'invoices' && <PremiumUserInvoices />}
          {activeItem === 'listen' && store.userPremium?.user?.feed && (
            <PremiumUserListen
              feedName={store.context.metadata.name}
              feed={store.userPremium?.user?.feed || ''}
              feedToken={store.context.feedToken || ''}
              spotifyShowUri={store.userPremium?.user?.spotifyShowUri || null}
              playerVar={store.userPremium?.user?.player}
            />
          )}
          {activeItem === 'listen' && !store.userPremium?.user?.feed && (
            <PremiumUserOffers />
          )}
        </div>
        {activeItem === 'account' && (
          <div className="account-container">
            <div className="account-user">
              <h3 style={{ fontSize: '1.15em' }}>
                {t('user.logged_as', { offer: store.context.metadata.name })}
              </h3>
              <p style={{ fontSize: '1.3em', paddingBottom: '1.5em' }}>
                {store.userPremium?.user?.email}
              </p>

              { store.userPremium?.isCanceled ? (
                <>
                  <div
                    style={{
                      fontSize: '1.2em',
                    }}
                  >
                    <p>
                      {t('user.canceled')}
                    </p>
                    {store.userPremium?.cancelAt && (
                      <span>{t('user.cancel_at', { cancelAt: store.userPremium?.cancelAt.split('T')[0] })}</span>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {isStream && store.userPremium?.user?.isStripeUser && (
                    <Button
                      style={{
                        marginBottom: '1.25em',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={toggleBtnAccount}
                      primary
                    >
                      <Icon name="credit card" inverted size="big" />
                      {t('user.update.text')}
                    </Button>
                  )}

                  {toggleUserAccount && (
                    <div className="change-payment">
                      <p style={{ fontSize: '1.1em' }}>
                        {t('user.update.infos')}
                      </p>

                      {store.stripe && (
                        <Elements stripe={store.stripe}>
                          <PremiumCBUpdate toggle={toggleBtnAccount} />
                        </Elements>
                      )}
                    </div>
                  )}

                  <Button
                    primary
                    onClick={toggleBtnUnsubscribe}
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon name="times" inverted size="big" />
                    {t('user.unsubscribe.button')}
                  </Button>

                  {toggleUserUnsubscribe && (
                    <div className="user-unsubscribe">
                      <p style={{ fontSize: '1.05em', margin: '1.2em' }}>
                        {t('user.unsubscribe.confirm', { offer: store.context.metadata.name })}
                      </p>
                      <Button primary onClick={() => userUnsubscribe()}>
                        {t('yes')}
                      </Button>
                      <Button
                        basic
                        style={{ border: '1px solid #d6d6d6' }}
                        onClick={toggleBtnUnsubscribe}
                      >
                        {t('no')}
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              style={{
                flex: 1,
                width: '100%',
              }}
            />
            <div
              className="container-footer"
              style={isStream ? null : stylePackFooter}
            >
              <div className="footer-member-area">
                <a
                  href={`mailto:${store.context.metadata.supportEmail}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('user.contact')}
                </a>

                {' | '}

                <a onClick={disconnectPremium}>{t('user.disconnect.button')}</a>

                {' | '}

                <a
                  href={`https://audiomeanscgu.s3.amazonaws.com/2021-10-08/offers/${store.context.pid}.pdf`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('user.cgv')}
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PremiumUserAccount;
