import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import { Shake } from 'reshake';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Store from '../store';
import { validateEmail } from '../stripe';

const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

const EmailField = ({
  placeholder,
  icon,
  fieldName,
}) => {
  const [t] = useTranslation();
  const store = Store.useContainer();

  const [loading, setLoading] = useState(false);

  const validator = async (e) => emailRegex.test(e);
  const transformer = (e) => e.trim().replace(/(\r\n|\n|\r)/gm, '');

  const [field, setField] = useState('');
  const [error, setError] = useState(false);
  const [blurError, setBlurError] = useState(false);
  const debouncedChangeHandler = useCallback(
    debounce((value) => {
      store.setForm((prev) => ({
        ...prev,
        [fieldName]: transformer(value),
      }));
    }, 300), [],
  );

  const handleBlur = async () => {
    setLoading(true);
    const { status } = await validateEmail(store.context.slug, field);
    if (status === 'INVALID') {
      setBlurError({
        text: t('payment.error.invalid_email'),
        class: 'form-field-error',
      });
    } else {
      const domain = (field || '').split('@');
      if (domain.length > 1) {
        let err = null;
        if (domain[1].indexOf('gma') === 0 && domain[1] !== 'gmail.com') {
          err = t('payment.error.invalid_email_gmail');
        } else if (domain[1].indexOf('yah') === 0 && !['yahoo.com', 'yahoo.fr'].includes(domain[1])) {
          err = t('payment.error.invalid_email_yahoo');
        } else if (domain[1].indexOf('hot') === 0 && !['hotmail.fr', 'hotmail.com'].includes(domain[1])) {
          err = t('payment.error.invalid_email_hotmail');
        }

        if (err) {
          setBlurError({
            text: err,
            class: 'form-field-warn',
          });
        }
      }
    }
    setLoading(false);
  };

  const [fieldShake, setFieldShake] = useState(false);
  useEffect(() => {
    if (!fieldShake && store.shake && (store.form[fieldName] || '').trim() === '') {
      setFieldShake(true);
    } else if (fieldShake && !(store.shake && (store.form[fieldName] || '').trim() === '')) {
      setFieldShake(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.shake, fieldShake, store.form[fieldName]]);

  return (
    <>
      <Shake h={10} v={0} r={0} q={4} active={fieldShake} fixed>
        <Input
          style={{ marginTop: '10px', marginBottom: '5px' }}
          placeholder={placeholder}
          fluid
          icon={icon}
          iconPosition="left"
          error={error}
          value={field}
          loading={loading}
          onBlur={handleBlur}
          onFocus={() => {
            setBlurError(null);
          }}
          onChange={async (evt, { value }) => {
            setField(transformer(value));
            setError(!(await validator(value)) || value.trim() === '');
            debouncedChangeHandler(value);
          }}
        />
      </Shake>
      { blurError && (
        <div className={`${blurError.class}`}>{blurError.text}</div>
      )}
    </>
  );
};

EmailField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
};

export default EmailField;
